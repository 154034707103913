import { Controller } from '@hotwired/stimulus';
import { requestChart } from '../charts/utils';
import { AsyncBox } from '../charts/async_box';
import ApexCharts from 'apexcharts';
import { historyBasicOptions } from '../utils/apexChart';
export default class extends Controller {
  static targets = ['data', 'box', 'loader', 'form'];

  initialize() {
    this.graphBox = AsyncBox(
      'pollutant history chart',
      this.loaderTarget,
      this.boxTarget
    );
  }
  
  connect() {
    this.loadGraph();
  }

  loadGraph() {
    this.graphBox.loading();
    requestChart(
      this.formTarget,
      (response) => {
        const options = historyBasicOptions({ ...response.data, height: 350 })
        const pollutantType = this.boxTarget.dataset.pollutantType; 
        const content = document.querySelector(
          `.js-graph-box[data-pollutant-type="${pollutantType}"]`
        )

        if (!content) {
          console.error(
            `Graph box for pollutant type ${pollutantType} not found`
          );
          this.graphBox.error()
          return
        }

      
        options.chart.id = pollutantType;

        if (this.chart) {
          this.chart.updateOptions(options)
          this.graphBox.loaded();
        } else {
          this.graphBox.loaded();
          this.chart = new ApexCharts(content, options);
          this.chart.render();
        };
      },
      (...args) => {
        this.graphBox.error(...args, new FormData(this.formTarget));
      }
    )
  }
}
