import { Controller } from "@hotwired/stimulus"
import Leaflet from 'leaflet';


export default class extends Controller {
  initialize() {
    this.places = JSON.parse(this.element.dataset.places) || [];

    // To match sort made for establishment list
    if (this.places.length > 0) {
      this.places = this.places.sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }))
    }

    this.map = Leaflet.map(this.element).fitBounds(this.places.map(place => place.coordinates));
    Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    for ( const [index, place] of this.places.entries() ) {
      const marker = Leaflet.marker(place.coordinates, {icon: this.icon(index + 1)}).addTo(this.map);
      marker.bindPopup(`<p class="m-board__subtitle">${place.title}</p><p class="m-board__details">${place.address}</p><div class="u-text-center u-mts"><a class="a-button a-button--small" href="${place.url}">Accéder à l'établissement</a></div>`);
    }
  }

  icon(number) {
    return L.divIcon({
      className: 'marker-icon-container',
            html: "<div class='marker-icon'></div>",
            iconSize: [20, 20],
            iconAnchor: [10, 0]
        });
  }
}