import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = [
    'checkboxCode',
    'spaces'
  ];

  connect() {
    this.codeByArea = []
    this.disabledCheckboxes()
  }

  filterParams() {
    const pollutants = []
    this.disabledCheckboxes()

    this.spacesTargets
        .filter((space) => space.checked)
        .forEach(({dataset}) => pollutants.push(...JSON.parse(dataset.measuredPollutants)))

    this.checkboxCodeTargets.forEach((checkbox) => {
      if (pollutants.includes(checkbox.value)) {
        checkbox.disabled = false;
        checkbox.nextSibling.classList.remove('is-desactivated')
      } else {
        checkbox.checked = false
        checkbox.nextSibling.classList.add('is-desactivated')
      }
    });
  }

  disabledCheckboxes() {
    this.checkboxCodeTargets.forEach((checkbox) => {
      checkbox.disabled = true
      checkbox.nextSibling.classList.add('is-desactivated')
    })
  }
}
