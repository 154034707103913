import { Controller } from "@hotwired/stimulus"
import { open, close, isOpened } from "../utils/display"

export default class extends Controller {
  static targets = ['panel', 'linkTrigger', 'svgTrigger']

  toggle() {
    if (isOpened(this.panelTarget)) {
      close(this.panelTarget)
    } else {
      open(this.panelTarget)
    } 
  }

  disableLink() {
    this.linkTriggerTarget.classList.add('is-hidden')
    this.svgTriggerTarget.classList.remove('is-hidden')
  }
}