import { Controller } from '@hotwired/stimulus'
import { initDateRangePicker } from '../utils/flatpickr'
import { hideV2, showV2 } from '../utils/display'
import {
	addDays,
	addMinutes,
	addMilliseconds,
	differenceInMilliseconds,
	endOfDay,
	endOfMinute,
	getTime,
	milliseconds,
	startOfDay,
	endOfWeek,
	startOfMinute,
	subDays,
	subHours,
	subMilliseconds,
	subYears,
	isFuture,
	startOfWeek
} from 'date-fns'

export default class extends Controller {
	static targets = [
		'startDate',
		'endDate',
		'dateRange',
		'buttonNext',
		'defaultRange',
		'clearButton'
	];

	static values = {
    locale: String,
    creationDates: String,
    minDate: Number,
    maxDate: Number
  }

	connect() {
    let defaultMinDate = subYears(new Date(), 1)
    let defaultMaxDate = endOfWeek(new Date(), { weekStartsOn: 1 })

    if (this.hasCreationDatesValue) {
      this.minDates = JSON.parse(this.creationDatesValue)
    }

    if (this.hasMinDateValue && this.minDateValue) {
      const minDate = new Date(this.minDateValue)
      if (minDate > defaultMinDate ) { defaultMinDate = minDate }
    }

    if (this.hasMaxDateValue && this.maxDateValue) {
      const maxDate = endOfDay(new Date(this.maxDateValue))
      if (maxDate < defaultMaxDate ) { defaultMaxDate = maxDate }
    }

		if (this.hasDateRangeTarget) {
			this.dateRangePicker = initDateRangePicker(
				this.dateRangeTarget,
				{
					locale: this.localeValue,
					minDate: defaultMinDate,
					maxDate: defaultMaxDate,
					onChange: (selectedDates) => {
						if (selectedDates.length === 2) {
							const startDate = startOfDay(selectedDates[0])
							const endDate = endOfDay(selectedDates[1])
	
							this.updateDateRange(startDate, endDate)
						}
						this.resetRange()
	
					},
					onReady: (_selectedDates, _dateStr, instance) => {
						setTimeout(() => {
							const dateInputContainer = document.querySelector('.date-input__container');
							dateInputContainer.appendChild(instance.calendarContainer);
						}, 0);
					}
				}
			)
		}
		
		const endOfNow 		 = endOfDay(new Date())
		const dispatch 		 = !!this.element.dataset.initDispatch || false
		const defaultRange = this.element.dataset.defaultRange || 2
		if (this.element.dataset.required != 'false') {
			this.updateDateRange(startOfDay(subDays(endOfNow, defaultRange)), endOfNow, dispatch)
		}
  }

	selectRange({ target }) {
		this.range = target.value
		const now = new Date()

		let endDate = isFuture(this.endDate) ? now : this.endDate
		let startDate = subHours(endDate, 1)

		switch (this.range) {
			case 'hours':
				startDate = subHours(endDate, 6)
				break
			case 'one_day':
			case 'day':
				endDate = endOfDay(endDate)
				startDate = startOfDay(endDate)
				break
			case 'two_days':
				endDate = endOfDay(endDate)
				startDate = subDays(startOfDay(endDate), 1)
				break
			case 'three_days':
				endDate = endOfDay(endDate)
				startDate = subDays(startOfDay(endDate), 2)
				break
			case 'week':
				endDate = endOfWeek(endDate, { weekStartsOn: 1 })
				startDate = startOfWeek(endDate, { weekStartsOn: 1 })
				break
			default:
				break
		}

		this.updateDateRange(startDate, endDate)
	}

	previousDateRange(event) {
		event.preventDefault()
		if (this.range === 'hours') { this.resetRange() }

		const timeRange = differenceInMilliseconds(this.endDate, this.startDate)
		const minuteStep = milliseconds({ hours: 1, minutes: 1 }) > timeRange

		const newEndDate = minuteStep
			? endOfMinute(this.startDate)
			: endOfDay(subDays(this.startDate, 1))

		const newStartDate = minuteStep
			? startOfMinute(subHours(newEndDate, 1))
			: startOfDay(subMilliseconds(getTime(newEndDate), timeRange))

		this.updateDateRange(newStartDate, newEndDate);
	}

	nextDateRange(event) {
		event.preventDefault();

		const timeRange = differenceInMilliseconds(this.endDate, this.startDate)
		const minuteStep = milliseconds({ hours: 1, minutes: 1 }) > timeRange

		const newStartDate = minuteStep
			? startOfMinute(addMinutes(this.endDate, 1))
			: startOfDay(addDays(this.endDate, 1));

		const newEndDate = minuteStep
			? endOfMinute(addMinutes(newStartDate, 59))
			: endOfDay(addMilliseconds(getTime(newStartDate), timeRange))

		this.updateDateRange(newStartDate, newEndDate)
	}

  updateFormField({ startDate, endDate }) {
		if (this.hasStartDateTarget && this.hasEndDateTarget) {
			this.startDateTarget.value = startDate
			this.endDateTarget.value = endDate
		}
  }

	updateDateRange(startDate, endDate, dispatch = true) {
		if (this.hasButtonNextTarget) { this.toggleNexButton(endDate) }
		if (this.hasClearButtonTarget) { showV2(this.clearButtonTarget)}

		if (startDate == endDate) { endDate = endOfDay(endDate) }

		if (this.dateRangePicker) { this.dateRangePicker.setDate([startDate, endDate]) }
		
		this.updateFormField({startDate, endDate})
		this.endDate = endDate
		this.startDate = startDate

		if (dispatch) {
      this.dispatch('change', { detail: { startDate, endDate }})
    }
	}

	resetRange() {
		this.range = null
		this.defaultRangeTargets.forEach(element => element.checked = false)
	}

	toggleNexButton(endDate) {
		if (isFuture(endDate)) {
			this.buttonNextTarget.setAttribute("disabled", true)
		} else {
			this.buttonNextTarget.removeAttribute("disabled")
		}
	}

	clearSelect() {
		hideV2(this.clearButtonTarget)
		this.updateFormField({startDate: '', endDate: ''})
		this.dateRangePicker.clear()
	}
}
