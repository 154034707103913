import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["navLink"]

  switchTab(event) {
    event.preventDefault();

    const tabName = event.target.dataset.tab;

    this.navLinkTargets.forEach(navLink => {
      const isActive = navLink === event.currentTarget;
      navLink.classList.toggle("is-active", isActive);
    });

    this.element.querySelectorAll('.tab-panel').forEach(tabPanel => {
      const isActiveTab = tabPanel.id === tabName;
      tabPanel.classList.toggle("is-active", isActiveTab);
    });
  }
}