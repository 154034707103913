import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["checkbox", "error"];

    submit(event) {
        if (!this.checkboxTarget.checked) {
            event.preventDefault();
            this.errorTarget.style.display = 'inline';
        } else {
            this.errorTarget.style.display = 'none';
        }
    }
}
