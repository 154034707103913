import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

const percent = (total, data) => {
  const percentage = (100 / total) * data;
  return parseFloat(percentage.toFixed(2));
};

export default class extends Controller {
  static targets = ["chart"];
  used = 0;
  unused = 0;
  total = 0;

  connect() {
    const elem = this.chartTarget;
    this.used = parseFloat(elem.getAttribute("data-used")) || 0;
    this.unused = parseFloat(elem.getAttribute("data-unused")) || 0;

    this.total = this.used + this.unused;

    this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
    this.chart.render();
  }

  get chartOptions() {
    return {
      chart: {
        type: 'donut',
        height: '136px',
        width: '136px',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      series: [percent(this.total, this.used), percent(this.total, this.unused)],
      colors: ["#397EE6", "#C5DCFF"],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'round',
        width: 0,
        dashArray: 0,
      },
      labels: ["used", "unused"],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
      }
    }
  }
}
