import { Controller } from '@hotwired/stimulus';
import { showV2, hideV2 } from '../utils/display';
import { format, differenceInSeconds } from 'date-fns';

const DEFAULT_MAX_DURATION = 31 * 24 * 60 *60

export default class extends Controller {
  static targets = [
    'startDate',
    'endDate',
    'submitButton',
    'errorMessage',
    'link',
    'rangeStep'
  ]

  static values = { maxDuration: Number, message: String }

  checkDateRangeContraints() {
    hideV2(this.errorMessageTarget)
    let maxDuration = this.maxDurationValue || DEFAULT_MAX_DURATION
    let message = this.messageValue || ''

    if (this.rangeStepTargets.length) {
      maxDuration = this.rangeStepTargets.filter(step => step.checked)[0].dataset.maxDuration
      message = this.rangeStepTargets.filter(step => step.checked)[0].dataset.message
    }
    const startDate = new Date(this.startDateTarget.value)
    const endDate = new Date(this.endDateTarget.value)
    const limitExceeded = Math.abs(differenceInSeconds(startDate, endDate)) > parseInt(maxDuration)

    if (limitExceeded) {
      this.submitButtonTarget.setAttribute('disabled', true)
      this.errorMessageTarget.childNodes[0].innerHTML = message
      showV2(this.errorMessageTarget)
    } else {
      this.submitButtonTarget.removeAttribute('disabled')
    }
  }

  update_link({ detail }) {
    const { startDate, endDate } = detail;

    const formattedStartDate = format(new Date(startDate), 'dd/MM/yyyy');
    const formattedEndDate = format(new Date(endDate), 'dd/MM/yyyy');

    let url = new URL(this.linkTarget.href);
    url.searchParams.append('start_date', formattedStartDate);
    url.searchParams.append('end_date', formattedEndDate);

    this.linkTarget.href = url;
  }
}
