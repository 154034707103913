import { Controller } from "@hotwired/stimulus"

const buildBadge = (param) => {
  const badge = document.createElement('span')
  badge.className = 'badge badge__medium badge__grey'
  badge.innerHTML = param

  return badge
}

const buildItem = (itemTemplate, sensorLabel, params, index) => {
  itemTemplate.getElementsByClassName('dual-listbox__table-item-title')[0].innerHTML = sensorLabel

  const listParams = itemTemplate.getElementsByClassName('dual-listbox__table-item__left-side-bottom')[0]
  if (params) {
    params.split(',').forEach(param => listParams.appendChild(buildBadge(param)))
  }
  itemTemplate.getElementsByClassName('icon-button')[0].setAttribute('data-dual-listbox2-index-param', index)

  return itemTemplate
}


export default class extends Controller {
  static targets = [
    'select',
    'availableBox',
    'selectedBox',
    'availableItem',
    'selectedItem',
    'searchAvailable',
    'searchSelected',
    'selectedCounter',
    'availableCounter'
  ]

  connect() {
    this.selectTarget.searchAvailable = this.searchAvailableTarget
    this.selectTarget.searchSelected  = this.searchSelectedTarget
    this.selectTarget.selected        = this.selectedBoxTarget
    this.selectTarget.available       = this.availableBoxTarget
    this.selectTarget.selectedCount   = this.selectedCounterTarget
    this.selectTarget.availableCount  = this.availableCounterTarget
    this.refresh_select(this.selectTarget)
  }

  add(event) {
    event.stopPropagation()
    this.selectTarget.options[event.params.index].selected = true
    this.refresh_select(this.selectTarget, this.availableItemTarget)
  }

  remove(event) {
    event.stopPropagation()
    this.selectTarget.options[event.params.index].selected = false
    this.refresh_select(this.selectTarget)
  }

  addAll() {
    this.selectTarget.selectableItemIndex.forEach(i => this.selectTarget.options[i].selected = true)
    this.refresh_select(this.selectTarget)
  }

  removeAll() {
    this.selectTarget.selectedItemIndex.forEach(i => this.selectTarget.options[i].selected = false)
    this.refresh_select(this.selectTarget)
  }

  search() {
    this.refresh_select(this.selectTarget)
  }

  refresh_select(select) {
    select.selected.innerHTML  = ""
    select.available.innerHTML = ""
    select.selectableItemIndex = []
    select.selectedItemIndex   = []
  
    const queryAvailable = select.searchAvailable.value
    const querySelected = select.searchSelected.value
  
    for (let i = 0; i < select.options.length; i++) {
      const option = select.options[i]
      const [sensorLabel, params] = (option.textContent || option.innerText).split('|')
  
      if (option.selected && (!querySelected || sensorLabel.toLowerCase().indexOf(querySelected.toLowerCase()) > -1)) {
        const itemTemplate = this.selectedItemTarget.content.cloneNode(true).childNodes[0]
        const item = buildItem(itemTemplate, sensorLabel, params, i)
        select.selected.appendChild(item)
        select.selectedItemIndex.push(i)
      } else if (!option.selected && (!queryAvailable || sensorLabel.toLowerCase().indexOf(queryAvailable.toLowerCase()) > -1)) {
        const itemTemplate = this.availableItemTarget.content.cloneNode(true).childNodes[0]
        const item = buildItem(itemTemplate, sensorLabel, params, i)
        select.available.appendChild(item)
        select.selectableItemIndex.push(i)
      }
    }
  
    select.selectedCount.textContent = select.selectedCount.textContent.replace(/\d+/, select.selectedItemIndex.length)
    select.availableCount.textContent = select.availableCount.textContent.replace(/\d+/, select.selectableItemIndex.length)
  }
}