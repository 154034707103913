import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['step', 'summaryCheckbox']

  connect() {
    const { id, dataset } = this.stepTarget

    this.dispatch(
      "sectionLoaded", 
      { detail: { sectionId: id, mainSection: dataset.mainSection, step: dataset.step } }
    )
  }

  checkSummary() {
    this.summaryCheckboxTarget.checked = true
  }
}