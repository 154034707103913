import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['progressBar']

  static values = { percentage: Number }

  initialize() {
    this.wait = 5
    const step = parseInt(this.progressBarTarget.dataset.step)
    this.intervalId = setInterval(() => {
      if (this.percentageValue < 100) {
        this.updateValue(this.percentageValue + step)
      } else if (this.wait >= 0) {
        this.wait--
      }

      if (this.wait == 0) {
        this.dispatch("loadingDone")
      }
    }, 75)
  }

  stopInterval() {
    clearInterval(this.intervalId)
  }

  updateValue(value) {
    this.percentageValue = value;
    this.progressBarTarget.style.width = `${this.percentageValue}%`;
  }
}