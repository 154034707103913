import { Controller } from '@hotwired/stimulus'
import { showV2, hideV2 } from '../utils/display';

export default class extends Controller {
  static targets = ['placeholder', 'establishment', 'area', 'checkboxGroup']

  displayZones({detail: { value }}) {
    value ? hideV2(...this.placeholderTargets) : showV2(...this.placeholderTargets)

    hideV2(...this.checkboxGroupTargets)

    this.areaTargets.forEach((target) => {
      if (value == target.dataset.establishmentId) {
        showV2(target)
      } else {
        target.querySelector('input').checked = false
        target.querySelector('input').dispatchEvent(new InputEvent('change'))
        hideV2(target)
      }
    })

    this.checkboxGroupTargets.forEach((group) => {
      if (group.querySelectorAll('.form-analyse__checkbox:not(.is-hidden)').length) { showV2(group) }
    })
  }
}
