import { Controller } from "@hotwired/stimulus"
import { hide } from "../utils/display"

export default class extends Controller {
  static targets = ['slug', 'nameInput']

  connect() {
    if (this.hasSlugTarget && this.slugTarget.dataset.url && this.slugTarget.dataset.formAction === 'create') {
      window.location.href = this.slugTarget.dataset.url
    }

    if (this.hasNameInputTarget) {
      this.nameInputTarget.focus()
    }
  }

  close() {
    hide([this.element])
  }

  disconnect() {
    hide([this.element])
  }

  submitEnd(event) {
    if (this.hasSlugTarget) {
      const action = this.slugTarget.dataset.formAction
      if (event.detail.success && ['edit', 'update'].includes(action)) {
        hide([this.element])
      }
    }
  }
}
