import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import { endOfDay, formatISO, subDays, startOfDay } from 'date-fns';

export default class extends Controller {
  static targets = ['errorMessage'];

  connect() {
    this.updateErrorMessage({
      currentTarget: this.stepTargets.filter((step) => step.checked)[0],
    });
  }

  updateErrorMessage(event) {
    this.errorMessageTarget.children[0].innerText =
      event.currentTarget.dataset.message;
  }
}
