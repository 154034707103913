import { Controller } from "@hotwired/stimulus"
import { hideV2, showV2 } from "../utils/display"

export default class extends Controller {
  static targets = ['address', 'gps', 'gpsInfos']

  toggle({ currentTarget }) {
    [this.addressTarget, this.gpsTarget].forEach(target => {
      Array.from(target.querySelectorAll('input')).forEach(input => input.value = '')
      hideV2(target)
    })
    showV2(this[`${currentTarget.value}Target`])
    this.gpsInfosTarget.textContent = ''
  }
}