import { Controller } from "@hotwired/stimulus"
import { hideV2, showV2, current, notCurrent } from "../utils/display"

export default class extends Controller {
  static targets = ['selected', 'option', 'legend']

  select({ currentTarget }) {
    notCurrent(this.optionTargets)
    current([currentTarget])
    this.selectedTarget.innerHTML = currentTarget.innerHTML
    this.updateLegend(currentTarget)
  }

  updateLegend(currentTarget) {
    if (this.hasLegendTarget) {
      let selectType = currentTarget.dataset.indicationSelectType
      let selectGradeName = currentTarget.dataset.indicationSelectGradeName

      if (selectType && selectGradeName) {
        const allLegends = this.legendTargets
        hideV2(...allLegends)

        const currentLegends = this.element.querySelector(`.legend-${selectType}-${selectGradeName}`)
        showV2(currentLegends)
      }
    }
  }
}