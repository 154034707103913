import { Controller } from "@hotwired/stimulus"
import { requestChart } from "../charts/utils";
import { show, hide, isHidden } from "../utils/display";
import { AsyncBox } from "../charts/async_box";

export default class extends Controller {
  static targets = [
    "placeholder",
    "chart",
    "box",
    "loader",
    "form",
  ];

  connect() {
    this.chartTargets.forEach((chart) => {
      return (chart["asyncBox"] = AsyncBox(
        `Comparison ${chart.dataset.pollutantCode}`,
        chart.querySelector('[data-comparison-charts-target$="loader"]'),
        chart.querySelector('[data-comparison-charts-target$="box"]')
      ));
    });
  }

  allChartsHidden() {
    return (
      this.chartTargets.filter(isHidden).length == this.chartTargets.length
    );
  }

  load(event) {
    event.preventDefault();
    hide(this.placeholderTarget);
    let graphs = [];
    
    const displayedPollutantCodes = new Set(
      Array.from(
        this.element.querySelectorAll("[data-measured-pollutants]:checked")
      ).flatMap((element) => JSON.parse(element.dataset.measuredPollutants))
    );

    this.chartTargets.forEach((chart) => {
      if (displayedPollutantCodes.has(chart.dataset.pollutantCode)) {
        chart.asyncBox.loading();
        show(chart);
      } else {
        hide(chart);
      }
    });

    const form = this.formTarget.cloneNode(true);

    requestChart(
      form,
      (response) => {
        this.chartTargets.forEach((chart) => {
          const code = chart.dataset.pollutantCode
          if (response[code]) {
            chart.asyncBox.load(response[code]);
          } else {
            hide(chart);
            if (this.allChartsHidden()) show(this.placeholderTarget);
          }
        });
      },
      (...args) => {
        this.chartTargets.forEach((chart) => {
          chart.asyncBox.error(...args, new FormData(form));
        });
      }
    );

    
  }
}
