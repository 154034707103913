import { roundToNearestMinutes, subMinutes } from "date-fns"

export const roundMinutes = (datetime) => {
  let roundDatetime = roundToNearestMinutes(datetime, { nearestTo: 10, roundingMethod: "floor" })

  if (roundDatetime > datetime) {roundDatetime = subMinutes(roundDatetime, 10)}

  return subMinutes(roundDatetime, 1)
}

export const invalidDate = (date) => date instanceof Date && isNaN(date)