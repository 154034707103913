import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["passwordInput", "toggleButton"];

  togglePassword() {
    const passwordInput = this.passwordInputTarget;
    const isPasswordVisible = passwordInput.type === "text";

    passwordInput.type = isPasswordVisible ? "password" : "text";
    this.toggleButtonTarget.classList.toggle("active", isPasswordVisible);

    const eyeIcon = this.toggleButtonTarget.querySelector(".eye-icon");
    const iconContent = isPasswordVisible ? eyeIcon.dataset.iconHide : eyeIcon.dataset.iconShow;
    eyeIcon.innerHTML = iconContent;
  }
}