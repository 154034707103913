import { Controller } from "@hotwired/stimulus"

export default class extends Controller {         
  static targets = ['realtimeForm', 'historyForm']

  submitRealtimeForm(event) {
    event.stopPropagation()
    if (this.hasRealtimeFormTarget) {
      this.realtimeFormTarget.requestSubmit()
    }
  }

  submitHistoryForm(event) {
    event.stopPropagation()
    if (this.hasHistoryFormTarget && !this.historyFormTarget.getElementsByTagName('turbo-frame')[0].childNodes.length) {
      this.historyFormTarget.requestSubmit()
    }
  }
}
