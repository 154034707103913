import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['removeBtn', 'list', 'template', 'card']

  connect() {
    this.removeBtnTarget.remove()
  }

  add() {
    const nextIndex = parseInt(this.listTarget.lastChild.dataset.index) + 1
    const establishment = this.templateTarget.content.cloneNode(true).childNodes[0]
    establishment.dataset.index = nextIndex
    establishment.innerHTML = establishment.innerHTML.replace(/establishment_index/g, nextIndex)
    this.listTarget.appendChild(establishment)
  }

  remove(event) {
    const index = event.currentTarget.dataset.index
    this.cardTargets.filter((card) => card.dataset.index === index)[0].remove()
  }
}