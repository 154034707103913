import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modalsOnboarding", "modalsContainer"];

  connect() {
    if (this.hasModalsOnboardingTarget && this.hasModalsContainerTarget) {
      this.currentModalValue = 1;
      this.currentModal = null;
      this.moveMainModal();
    }
  }

  moveMainModal() {
    const modalsOnboarding = this.modalsOnboardingTarget;
    const lAppBody = document.querySelector('.l-app__body');
    lAppBody.parentNode.insertBefore(modalsOnboarding, lAppBody);
  }

  openModal(event) {
    const {modalKey} = event.currentTarget.dataset;

    this.currentModal = this.modalsContainerTargets.find(modalContainerTarget => {
      return modalContainerTarget.dataset.modalKey === modalKey;
    });

    this.currentModal.classList.add("is-open");

    const currentContent = this.currentModal.querySelector('.modal-content')
    currentContent.classList.add("is-active");
  }

  nextModal() {
    const modalContents = Array.from(this.currentModal.querySelectorAll('.modal-content'));

    const index = modalContents.findIndex(modalContent => {
      return modalContent.classList.contains('is-active');
    })

    const currentModalContent = modalContents[index];
    currentModalContent.classList.remove('is-active');

    const nextModalContent = modalContents[index + 1];
    if (!nextModalContent) {
      // TODO finish form
      return this.closeModal();
    }

    // next modal exists
    nextModalContent.classList.add('is-active')
  }

  closeModal() {
    this.currentModal.querySelectorAll('.modal-content.is-active').forEach(modalContent => {
      modalContent.classList.remove('is-active');
    });

    this.currentModal.classList.remove('is-open');
    this.currentModal = null;
  }
}
