import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use"
import { open, close, isOpened } from "../utils/display"

const DATATABLES = [
  '/alerts/',
  '/grant_holders/',
  '/configurations/rooms',
  '/configurations/sensors/',
  '/configurations/equipment/',
  '/configurations/outdoor_zones',
  '/configurations/establishments'
]

export default class extends Controller {
  static targets = [
    'link',
    'filter',
    'option',
    'sortPanel',
    'filterGroup',
    'filterPanel',
    'sortDropdown',
    'currentSorting',
    'toggleForm',
    'linkToCopy',
    'icon'
  ]

  resetClickOutside() {
    if(this.hasSortPanelTarget) {
      useClickOutside(this, { element: this.sortDropdownTarget })
    }
  }

  changeEstablishmentUrl() {
    const url = new URL(window.location.href)
    const tab = url.pathname.split('/').pop()

    if (['sensor_groups', 'equipment'].includes(tab)) {
      url.pathname = url.pathname.split('/').slice(0,-1).join('/')
      url.searchParams.set('tab', tab)
  
      window.history.replaceState(null, '', url.href)
    }
  }

  updateUrl({detail}) {
    const {response = {url: ""}} = (detail.fetchResponse || {})
    if (DATATABLES.some(datatable => response.url.includes(datatable))) {
      const query_params = new URL(response.url).search
      history.pushState(null, '', `${window.location.pathname}${query_params}`)
      this.updateLink()
    }
  }

  updatePageQueryParam({detail: {currentPage}}) {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', currentPage)
    history.pushState(null, '', `${window.location.pathname}?${searchParams.toString()}`)

    this.updateLink()
  }

  updateLink() {
    const query_params = new URL(window.location.href).search
    if (query_params) {
      this.linkTargets.forEach(link => {
        const old_link = new URL(link.href)
        link.href = `${old_link.pathname}${query_params}`
      })
    }
  }

  toggleSort() {
    isOpened(this.sortPanelTarget) ? close(this.sortPanelTarget) : open(this.sortPanelTarget)
  }

  toggleFilterPanel() {
    isOpened(this.filterPanelTarget) ? close(this.filterPanelTarget) : open(this.filterPanelTarget)
  }

  toggleFilterGroup(event) {
    const group = event.params.filterGroup
    const panel = this.filterGroupTargets.filter((filter) => filter.dataset.filterGroup == group)[0]
    isOpened(panel) ? close(panel) : open(panel)
  }

  clickOutside() {
    if (this.hasSortPanelTarget) {
      close(this.sortPanelTarget)
    }
  }

  closeFilter() {
    if(this.hasFilterPanelTarget) {
      close(this.filterPanelTarget)
    }
  }

  clearFilter() {
    this.filterTargets.forEach(filter => filter.checked = false)
  }

  removeFilter(event) {
    event.currentTarget.parentElement.remove()
  }

  submitToggle(event) {
    event.preventDefault();
    const form = this.toggleFormTarget;
    if (form) {
      form.requestSubmit();
    }
  }

  copyLink(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.linkToCopyTarget.href)
      .then(() => {
        this.iconTarget.classList.remove('icon-duplicate');
        this.iconTarget.classList.add('icon-check');
        setTimeout(() => {
          this.iconTarget.classList.remove('icon-check');
          this.iconTarget.classList.add('icon-duplicate');
        }, 2000);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  }
}