// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

// cf https://github.com/stimulusjs/stimulus/issues/222
const hackApplicationStartUp = application => {
  application.router.stop();
  application.dispatcher.stop();
  application.dispatcher.start();
  application.router.start();
  return application;
};

const application = hackApplicationStartUp(Application.start());

const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));
