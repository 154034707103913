import { Controller } from "@hotwired/stimulus"
import { startOfDay, endOfDay, format } from "date-fns";
import { AsyncBox } from "../charts/async_box";
import { requestChart } from "../charts/utils";
import { DateRange } from "../charts/date_range";

export default class extends Controller {
  static targets = [
    "graph",
    "box",
    "loader",
    "form",
    "day",
    "week",
    "month",
    "next",
    "startDate",
    "endDate",
    "dateRange",
  ];

  initialize() {
    // FIXME l'initialisation devrait se baser sur le radio selectionné dans l'interface
    // Pour l'instant c'est l'inverse, la valeur du radio sélectionné est forcée ici
    this.dayTarget.checked = true;
    this.weekTarget.checked = false;
    this.monthTarget.checked = false;

    this.locales = JSON.parse(this.data.get('locales'));

    const now = new Date();
    this.dateRange = DateRange({
      startDate: startOfDay(now),
      endDate: endOfDay(now),
      minDate: undefined,
      maxDate: now,
      stepSize: "day",
      onDateChange: this.synchronizeDateChange.bind(this),
    });

    this.graphBox = AsyncBox(
      "Rooms repartition evolution chart",
      this.loaderTarget,
      this.boxTarget
    );
  }

  connect() {
    this.dateRange.init();
  }

  loadGraph() {
    this.graphBox.loading();
    requestChart(
      this.formTarget,
      (response) => {
        this.graphBox.load(response["graph"]);
      },
      (...args) => {
        this.graphBox.error(...args, new FormData(this.formTarget));
      }
    );
  }

  changeCategory() {
    this.loadGraph();
  }

  previousPeriod() {
    this.dateRange.backward();
  }

  nextPeriod() {
    this.dateRange.forward();
  }

  changeDateRange() {
    this.dateRange.step(this.stepSize());
  }

  stepSize() {
    if (this.monthTarget.checked === true) {
      return "month";
    } else if (this.weekTarget.checked === true) {
      return "week";
    } else {
      return "day";
    }
  }

  synchronizeDateChange(startDate, endDate, dateRange) {
    this.dateRange = dateRange;

    if (this.dateRange.hasNext()) {
      this.nextTarget.classList.remove("a-time-picker__next--disabled");
    } else {
      this.nextTarget.classList.add("a-time-picker__next--disabled");
    }

    this.dateRangeTarget.innerHTML = `${this.locales.from} ${format(
      startDate,
      "dd/LL/yyyy"
    )} ${this.locales.to} ${format(endDate, "dd/LL/yyyy")}`;

    this.startDateTarget.value = startDate;
    this.endDateTarget.value = endDate;
    this.loadGraph();
  }
}
