import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static targets = ["chart"];

  initialize() {
    this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
    this.chart.render();
  }

  get chartOptions() {
    return {
      chart: {
        type: 'pie',
        height: '192px',
        width: '192px',
      },
      series: [20, 50, 30],
      colors: ["#00D61F", "#FFD900", "#FF3E56"],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'round',
        width: 1,
        dashArray: 0,
      },
      labels: ["Good", "Intermediate", "Bad"],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
    }
  }
}
