import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

const percent = (total, data) => {
  const percentage = (100 / total) * data;
  return parseFloat(percentage.toFixed(2));
};

export default class extends Controller {
  static targets = ["chart"];
  used = 0;
  unused = 0;
  total = 0;

  initialize() {
    document.addEventListener("DOMContentLoaded", () => {
      const elem = this.chartTarget;
      this.used = parseFloat(elem.getAttribute("data-used")) || 0;
      this.unused = parseFloat(elem.getAttribute("data-unused")) || 0;

      this.total = this.used + this.unused;

      this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
      this.chart.render();
    });
  }

  get chartOptions() {
    return {
      chart: {
        type: 'donut',
        width: 54,
        height: 54,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        width: 0
      },
      tooltip: {
        fixed: {
          enabled: false
        },
      },
      series: [percent(this.total, this.used), percent(this.total, this.unused)],
      colors: ["#397EE6", "#C5DCFF"],
      labels: ["used", "unused"],
    }
  }
}