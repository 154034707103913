import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

const percent = (total, data) => {
  const percentage = (100 / total) * data;
  return parseFloat(percentage.toFixed(2)); // Conserve deux décimales
};

export default class extends Controller {
  static targets = ["chart"];
  good = 0;
  intermediate = 0;
  bad = 0;
  offline = 0;
  total = 0;

  connect() {
    const elem = this.chartTarget;
    this.good = parseFloat(elem.getAttribute("data-good")) || 0;
    this.intermediate = parseFloat(elem.getAttribute("data-intermediate")) || 0;
    this.bad = parseFloat(elem.getAttribute("data-bad")) || 0;
    this.offline = parseFloat(elem.getAttribute("data-offline")) || 0;

    this.total = this.good + this.intermediate + this.bad + this.offline;
    this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
    this.chart.render();
  }

  get chartOptions() {
    return {
      chart: {
        type: 'donut',
        height: '160px',
        width: '160px',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      series: [percent(this.total, this.good), percent(this.total, this.intermediate), percent(this.total, this.bad), percent(this.total, this.offline)],
      colors: ["#00D61F", "#FFD900", "#FF3E56", "#848894"],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'round',
        width: 0,
        dashArray: 0,
      },
      labels: ["Good", "Intermediate", "Bad", "Offline"],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
      }
    }
  }
}
