import { Controller } from "@hotwired/stimulus"
import Leaflet from 'leaflet'
import 'leaflet.markercluster'
import { hideV2, showV2 } from '../utils/display'

export default class extends Controller {
  static targets = [
    'map',
    'chartData',
    'roomsChart',
    'outdoorZonesChart',
    'equipmentChart',
    'sensorChart',
    'establishmentName',
    'establishmentAddress',
    'establishmentUrl'
  ]

  connect() {
    this.places = JSON.parse(this.mapTarget.dataset.places) || []
    this.bounds = this.places.map(place => place.coordinates)
    this.firstEstablishmentId = this.chartDataTargets[0].dataset.id
  }

  initMap() {
    if (this.map) { 
      this.map.fitBounds(this.bounds, {maxZoom: 13})
      return
     }

    this.map = Leaflet.map(this.mapTarget).fitBounds(this.bounds, {maxZoom: 13})
    this.map.createPane('labels')

    Leaflet.tileLayer(
      'https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png',
      {attribution: '©OpenStreetMap, ©CartoDB'}
    ).addTo(this.map)

    Leaflet.tileLayer(
      'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png',
      {pane: 'labels'}
    ).addTo(this.map)

    this.markersLayout = Leaflet.markerClusterGroup({
      showCoverageOnHover: false,
      maxClusterRadius: 10,
      iconCreateFunction: function(cluster) {
        return Leaflet.divIcon({
          html: `<div><span>${cluster.getChildCount()}</span></div>`,
          className: 'marker-cluster'
        })
      }
    })

    for ( const [_index, place] of this.places.entries() ) {
      const marker = Leaflet.marker(place.coordinates, {icon: this.icon(this.firstEstablishmentId == place.id , place)})
      marker.bindPopup(`<p class="marker-tooltip">${place.name}</p>`)
      marker.on('mouseover', function (_e) { this.openPopup(); });
      marker.on('mouseout', function (_e) { this.closePopup(); });
      marker.on('click', this.selectEstablishment, this);

      this.markersLayout.addLayer(marker)
    }

    this.markersLayout.on('clusterclick', function(a) {
      a.layer.zoomToBounds({padding: [20, 20]});
    })
    this.map.addLayer(this.markersLayout)
    this.updateCharts({detail: {id: this.firstEstablishmentId, type: 'establishment'}})
  }

  selectEstablishment({originalEvent: { target }}) {
    const { id, type } = target.dataset
    if (!id) { return }
    
    this.updateEstablishment(id, type)
    const markers = document.getElementsByClassName('marker-icon')
    for(let marker of markers) { marker.classList.remove('is-active') }
		const clickEvent = new CustomEvent('click-marker', {detail: {id, type}})

		this.map._container.dispatchEvent(clickEvent)

    target.classList.add('is-active')
  }

  icon(isActive, { grade, id, type }) {
    let html = `<div class='marker-icon ${grade} ${isActive ? 'is-active' : ''}' data-id=${id} data-type=${type} />`

    if (type === 'outdoor_sensor') {
      html = `<div class='sensor-marker-icon ${grade} ${isActive ? 'is-active' : ''}' data-id=${id} data-type=${type} />`
    }

    return Leaflet.divIcon({html, className: 'marker-icon-container', iconAnchor: [15, 15]})
  }

  updateCharts({ detail: { id, type } }) {
    const { dataset } = this.chartDataTargets.filter(({dataset}) => dataset.id === id && dataset.type === type)[0]
    const { rooms, outdoorZones, equipment, roomsGrades, outdoorZonesGrades, equipmentGrades } = dataset

    if (type === 'outdoor_sensor') {
      this.updateSensorChart(dataset)
    } else {
      hideV2(this.sensorChartTarget)
    }
  
    if (rooms) {
      this.updateRoomsChart(JSON.parse(rooms), roomsGrades)
    } else {
      hideV2(this.roomsChartTarget)
    }

    if (outdoorZones) {
      this.updateOutdoorZonesChart(JSON.parse(outdoorZones), outdoorZonesGrades)
    } else {
      hideV2(this.outdoorZonesChartTarget)
    }

    if (equipment) {
      this.updateEquipmentChart(JSON.parse(equipment), equipmentGrades)
    } else {
      hideV2(this.equipmentChartTarget)
    }
  }

  updateSensorChart(dataset) {
    const { colors } = dataset
    showV2(this.sensorChartTarget)
    this.toggleOutdoorTitle(dataset)
    const customEvent = new CustomEvent('update-chart', {detail: {series: [1], labels: [], colors}})
    document.getElementById('sensor-chart').dispatchEvent(customEvent)
  }

  updateOutdoorZonesChart({total, series, labels}, grades) {
    showV2(this.outdoorZonesChartTarget)
    const outdoorZonesCount = document.getElementById('outdoor-zones-chart-count')
    outdoorZonesCount.textContent = outdoorZonesCount.textContent.replace(/\d+/, total)
    document.getElementById('outdoor-zones-chart').dispatchEvent(this.updateEvent(series, labels, grades))
  }

  toggleOutdoorTitle({name, address, url, gradeText}) {
    const title = this.sensorChartTarget.querySelector('.card-head-donut__title')
    this.sensorChartTarget.querySelector('.card-head-donut__number').textContent = address
    this.sensorChartTarget.querySelector('.sensor-grade').textContent = ` ${gradeText}`

    title.textContent = name
    title.href = url
  }

  updateRoomsChart({total, series, labels}, grades) {
    showV2(this.roomsChartTarget)
    const roomsCount = document.getElementById('rooms-chart-count')
    roomsCount.textContent = roomsCount.textContent.replace(/\d+/, total)
    document.getElementById('rooms-chart').dispatchEvent(this.updateEvent(series, labels, grades))
  }

  updateEquipmentChart({total, series, labels}, grades) {
    showV2(this.equipmentChartTarget)
    const equipmentCount = document.getElementById('equipment-chart-count')
    equipmentCount.textContent = equipmentCount.textContent.replace(/\d+/, total)
    document.getElementById('equipment-chart').dispatchEvent(this.updateEvent(series, labels, grades))
  }

  updateEvent(series, labels, grades) {
    return new CustomEvent('update-chart', {detail: {series, labels, grades}})
  }

  updateEstablishment(id, type) {
    const { dataset } = this.chartDataTargets.filter(({dataset}) => dataset.id === id && dataset.type === type)[0]
    if (type === 'establishment') {
      this.establishmentNameTarget.textContent = dataset.name
      this.establishmentAddressTarget.textContent = dataset.address
    } else {
      this.establishmentNameTarget.textContent = dataset.establishmentName
      this.establishmentAddressTarget.textContent = dataset.establishmentAddress
    }
    this.establishmentUrlTarget.href = dataset.url
  }
}