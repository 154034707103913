import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  connect() {
    const closeOnSelect = !this.element.hasAttribute('multiple')
    const { openPosition, searchPlaceholder, searchText } = this.element.dataset

    this.slimSelect = new SlimSelect({
      select: this.element,
      settings: {
        showSearch: searchPlaceholder || false,
        allowDeselectOption: true,
        searchHighlight: true,
        placeholderText: 'Select Value',
        searchText,
        searchPlaceholder,
        closeOnSelect,
        openPosition 
      }
    })
  }

  disconnect() {
    this.slimSelect.destroy()
  }
}
