import { Controller } from '@hotwired/stimulus'
import { showV2, hideV2 } from "../utils/display";

export default class extends Controller {
  static targets = ['template', 'input', 'sensorList', 'cell', 'removeBtn']

  initialize() {
    this.detectCheckedRadioButton('0')
    this.cellTargets[0].querySelector('button').remove()
  }

  detectCheckedRadioButton(index) {
    const cell = this.cellTargets.filter(({dataset}) => dataset.index == index)[0]
    const firstRadio = cell.querySelector('input[type="radio"]')
    firstRadio.checked = true
    this.updateModel(firstRadio.dataset.model)
  }

  add_sensor() {
    this.removeBtnTarget.disabled = false

    const newIndex = new Date().valueOf()
    const sensorItem = this.templateTarget.content.cloneNode(true).childNodes[0]
    sensorItem.dataset.index = newIndex
    sensorItem.innerHTML = sensorItem.innerHTML.replace(/sensor_index/g, newIndex)
    this.sensorListTarget.appendChild(sensorItem)
    this.detectCheckedRadioButton(newIndex)
  }

  remove_sensor(event) {
    const index = event.currentTarget.dataset.index
    this.cellTargets.filter((cell) => cell.dataset.index === index).forEach((element) => element.remove())
  }

  remove_last_sensor(event) {
    const lastChild = this.sensorListTarget.lastChild
    if (lastChild.dataset.index != '0') {
      this.sensorListTarget.lastChild.remove()
    }

    if (this.cellTargets.length < 2) {
      this.removeBtnTarget.disabled = true
    }
  }

  select({currentTarget}) {
    this.updateModel(currentTarget.dataset.model)
  }

  updateModel(model) {
    const rowIndex = model.split('-')[1]
    this.inputTargets.filter((input) => input.dataset.index === rowIndex).forEach(element => {
      element.getElementsByTagName('input')[0].required = false
      element.getElementsByTagName('input')[0].value = ""
      hideV2(element)
    })
    const input = this.inputTargets.filter((input) => input.dataset.inputFor === model)[0]
    input.getElementsByTagName('input')[0].required = true
    showV2(input)
  }
}