import { Controller } from "@hotwired/stimulus"
import { showV2, hideV2, activate, desactivate, isActivated } from "../utils/display"

const selectedRadioValue = (radioButtons) =>
  radioButtons.filter(selection => selection.checked)[0].value

const checkAll = (list) => list.forEach( toggle => toggle.checked = true)
const uncheckAll = (list) => list.forEach( toggle => toggle.checked = false)

const childrenAllChecked = (children, feature) =>
  children.filter(child => child.dataset.parent === feature).every(element => element.checked)


const checkParents = (allParents, allChildren) => {
  const groupedByParent = allChildren.reduce((acc, child) => {
    (acc[child.dataset.parent] = acc[child.dataset.parent] || []).push(child)
    return acc
  }, {})

  for (const feature in groupedByParent) {
    if (Object.hasOwnProperty.call(groupedByParent, feature)) {
      if (childrenAllChecked(groupedByParent[feature], feature)) {
        checkAll(allParents.filter(parent => parent.dataset.feature === feature))
      }
    }
  }
}

export default class extends Controller {
  static targets = [
    'areaTypeSelect',
    'kindSelection',
    'expertGrants',
    'standardGrants',
    'sibling',
    'child',
    'parent',
    'areaEstablishmentSelect',
    'areasSelection',
    'areas',
    'tabTemplate',
    'tabList',
    'tab',
    'tabPanel',
    'counter'
  ]

  connect() {
    if (this.hasKindSelectionTarget) {
      if (selectedRadioValue(this.kindSelectionTargets) === "expert") {
        showV2(this.expertGrantsTarget)
        hideV2(this.standardGrantsTarget)
      } else {
        hideV2(this.expertGrantsTarget)
        showV2(this.standardGrantsTarget)
      }

      checkParents(this.parentTargets, this.childTargets)
    }
  }

  onAreaTypeChange({detail: { value }}) {
    this.areaType = value
    switch (value) {
      case 'Company':
        hideV2(this.areaEstablishmentSelectTarget)
        hideV2(this.areasSelectionTarget)
        break;
      case 'Establishment':
        showV2(this.areaEstablishmentSelectTarget)
        hideV2(this.areasSelectionTarget)
        break;
      case 'Area':
        showV2(this.areaEstablishmentSelectTarget)
        if (this.tabTargets.length) {
          showV2(this.areasSelectionTarget)
        } else {
          hideV2(this.areasSelectionTarget)
        }
        break;
      default:
        break;
    }
  }

  onEstablishmentSelect(event) {
    const { field, value} = event.detail
    const ids = value.map(val => val.id)

    if (this.areaType = 'Area' && field === 'granted_establishment_ids') {
      showV2(this.areasSelectionTarget)
      desactivate(this.tabTargets.map(tab => tab.children[0]))
      value.forEach(val => this.buildTab({...val, active: val.id == ids[0]}))
      const activePanel = this.tabPanelTargets.filter(panel => panel.dataset.id == ids[0])[0]
      hideV2(...this.tabPanelTargets)
      showV2(activePanel)
      this.initTabCounters(ids)
    } else {
      hideV2(this.areasSelectionTarget)
    }
  }

  onEstablishmentRemove({detail: {value}}) {
    const tab = this.tabTargets.filter(tab => tab.dataset.id == value)[0]
    const panel = this.tabPanelTargets.filter(panel => panel.dataset.id == value)[0]
    const newCurrentTab = this.tabTargets.filter(tab => tab.dataset.id != value)[0]

    if (isActivated(tab.childNodes[0]) && newCurrentTab) {
      const { id } = newCurrentTab.dataset
      newCurrentTab.childNodes[0].classList.add('is-active')
      this.tabPanelTargets.filter(panel => panel.dataset.id == id)[0].classList.remove('is-hidden')
    }

    if (!newCurrentTab) { hideV2(this.areasSelectionTarget) }

    panel.classList.add('is-hidden')
    const checkboxes = panel.querySelectorAll('input[type="checkbox"]:checked')
    for (const checkbox of checkboxes) { checkbox.checked = false }
    tab.remove()
  }

  changeTab(event) {
    hideV2(...this.tabPanelTargets)
    desactivate(this.tabTargets.map(tab => tab.children[0]))
    const { params: { tabId }, currentTarget } = event
    const activePanel = this.tabPanelTargets.filter(panel =>panel.dataset.id == tabId)[0]
    activate([currentTarget])
    showV2(activePanel)
  }

  buildTab({id, name, active}) {
    const tabTemplate = this.tabTemplateTarget.content.cloneNode(true).childNodes[0]
    tabTemplate.dataset.id = id
    const button = tabTemplate.childNodes[0]
    button.dataset.grantHolderFormTabIdParam = id
    button.childNodes[0].innerHTML = name
    button.childNodes[1].dataset.id = id

    if (active) {
      button.classList.add('is-active')
    }

    this.tabListTarget.appendChild(tabTemplate)
  }

  initTabCounters(ids) {
    const counters = {}
    const countersToInit = this.counterTargets.filter(counter => ids.includes(counter.dataset.id))
    const availableTabPanel = this.tabPanelTargets.filter(panel => ids.includes(panel.dataset.id))

    availableTabPanel.forEach(panel => {
      counters[panel.dataset.id] = panel.querySelectorAll('input[type="checkbox"]:checked').length
    })

    countersToInit.forEach(counter => counter.innerHTML = counters[counter.dataset.id])
  }

  updateCounter(event) {
    const { id } = event.detail || event.currentTarget.dataset

    const panel = this.tabPanelTargets.filter(panel => panel.dataset.id == id)[0]
    const counter = this.counterTargets.filter(counter => counter.dataset.id == id)[0]
    counter.innerHTML = panel.querySelectorAll('input[type="checkbox"]:checked').length
  }

  onGrantKindChange() {
    if (selectedRadioValue(this.kindSelectionTargets) === "expert") {
      showV2(this.expertGrantsTarget)
      hideV2(this.standardGrantsTarget)
      this.changeAreaType()
    } else if (selectedRadioValue(this.kindSelectionTargets) === "standard") {
      showV2(this.standardGrantsTarget)
      hideV2(this.expertGrantsTarget)
    }
  }

  changeAreaType() {
    const areaTypeSelect = this.areaTypeSelectTarget.getElementsByTagName('select')[0]
    const areaTypeSelected = this.areaTypeSelectTarget.getElementsByClassName('dropdown-option-select__selected')[0]
    const companyOption = this.areaTypeSelectTarget.querySelector('[data-custom-select-value-param="Company"]')

    areaTypeSelect.value = 'Company'
    areaTypeSelected.textContent = companyOption.dataset.label
  }

  toggleCrudFeature(event) {
    const feature  = event.target.dataset.feature.split('_').slice(0, -1).join('_')
    const parentFeature = event.target.dataset.parent
    const haveChildren  = event.target.dataset.haveChildren
    const checked  = event.target.checked
    const siblings = this.siblingTargets.filter(sibling => sibling.dataset.feature === feature)

    if (checked) {
      checkAll(siblings)
    }

    if (haveChildren) {
      this.toggleChildrenFeature(event)
    }

    if (!checked && parentFeature) {
      const parents = this.parentTargets.filter(parent => parent.dataset.feature === parentFeature)
      uncheckAll(parents)
    }

    if(parentFeature && childrenAllChecked(this.childTargets, parentFeature)) {
      const parents = this.parentTargets.filter(parent =>
        [parentFeature, parentFeature.split('_').slice(0, -1).join('_')].includes(parent.dataset.feature)
      )
      checkAll(parents)
    }
  }

  toggleShowFeature(event) {
    const feature       = event.target.dataset.feature
    const parentFeature = event.target.dataset.parent
    const haveChildren  = event.target.dataset.haveChildren
    const checked       = event.target.checked
    const crudSiblings  = this.siblingTargets.filter(sibling => sibling.dataset.feature === `${feature}_crud`)
    const crudChecked   = crudSiblings.some(sibling => sibling.checked)

    if (!checked && crudChecked) {
      uncheckAll(crudSiblings)
    }

    if (haveChildren) {
      this.toggleChildrenFeature(event)
    }

    if (!checked && parentFeature) {
      const parents = this.parentTargets.filter(parent =>
        [parentFeature, `${parentFeature}_crud`].includes(parent.dataset.feature)
      )
      uncheckAll(parents)
    }

    if(childrenAllChecked(this.childTargets, parentFeature)) {
      const parents = this.parentTargets.filter(parent => parent.dataset.feature === parentFeature)
      checkAll(parents)
    }
  }

  toggleChildrenFeature(event) {
    let feature        = event.target.dataset.feature
    const checked      = event.target.checked
    let children       = this.childTargets.filter(child => child.dataset.parent === feature)
    const crud_feature = feature.includes('_crud')

    if (crud_feature && checked) {
      feature = feature.split('_').slice(0, -1).join('_')
      children = [...children, ...this.childTargets.filter(child => child.dataset.parent === feature)]
    }

    if (!crud_feature && !checked) {
      children = [...children, ...this.childTargets.filter(child => child.dataset.parent === `${feature}_crud`)]
    }

    if (checked) {
      checkAll(children)
    } else {
      uncheckAll(children)
    }
  }
}
