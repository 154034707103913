import { Controller } from '@hotwired/stimulus'
import { hideV2, showV2 } from '../utils/display'


export default class extends Controller {
  static targets = ['container', 'supervisor', 'link', 'linkInput', 'fullLink', 'shareButtons']

  connect() {
    this.supervisorInput = this.element.querySelector('select')
    this.supervisorSelected = this.element.querySelector('span[data-custom-select-target=selected]')

    if (this.supervisorInput.value) this.showSupervisor()
    if (this.linkInputTarget.dataset.persisted === 'true') {
      this.showLink()
    } else {
      this.linkInputTarget.value = null
    }
  }

  back() {
    this.supervisorInput.selectedIndex = 0
    this.supervisorSelected.textContent = ''
    this.linkInputTarget.value = null
    showV2(this.shareButtonsTarget)
    hideV2(this.supervisorTarget, this.linkTarget, this.containerTarget)
  }

  showSupervisor() {
    this.linkInputTarget.value = null
    this.supervisorInput.disabled = false
    showV2(this.supervisorTarget, this.containerTarget)
    hideV2(this.shareButtonsTarget)
  }

  showLink() {
    this.supervisorInput.selectedIndex = 0
    this.supervisorSelected.textContent = ''
    this.linkInputTarget.value = this.linkInputTarget.dataset.publicLink
    showV2(this.linkTarget, this.containerTarget)
    hideV2(this.shareButtonsTarget)
  }

  copy() {
    this.fullLinkTarget.select()
    navigator.clipboard.writeText(this.fullLinkTarget.value)
    setTimeout(() => {
      this.fullLinkTarget.selectionStart = this.fullLinkTarget.selectionEnd
    }, 500)
  }
}