import { Controller } from '@hotwired/stimulus'
import { isHiddenV2 } from '../utils/display'

export default class extends Controller {
	static targets = ['field', 'latitude', 'longitude', 'city', 'zip_code', 'gpsInfos']

	connect() {
		this.initializeMap()
	}

	initializeMap() {
		if (typeof (google) != 'undefined' && this.hasFieldTarget) {
			this.autocomplete()
		}
	}

	autocomplete() {
		if (this._autocomplete == undefined) {
			this._autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
			this._autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
			this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
		}
		return this._autocomplete
	}

	latLngChanged() {
		this.geocoder ||= new google.maps.Geocoder()

		if (this.latLngFilled()) {
			const latlng = {
				lat: parseFloat(this.latitudeTarget.value),
				lng: parseFloat(this.longitudeTarget.value)
			}

			this.geocoder
				.geocode({ location: latlng })
				.then(({results}) => {
					if (results[0]) {
						const { address_components } = results[0]
						if (address_components.length > 1) {
							this.setCityPostcode(address_components)
							this.setFormattedAddress(address_components)
						} else {
							this.setGpsInfos('notFoundMessage')
						}
					} else {
							this.setGpsInfos('notFoundMessage')
					}
				})
				.catch(e => {
					this.setGpsInfos('errorMessage')
				})
		} else {
			this.setGpsInfos('')
		}
	}

	setFormattedAddress(components) {
		const route = components.find(comp => comp.types[0] === 'route')?.long_name
		const locality = components.find(comp => comp.types[0] === 'locality')?.long_name
		const postalCode = components.find(comp => comp.types[0] === 'postal_code')?.long_name
		const country = components.find(comp => comp.types[0] === 'country')?.long_name
		
		let address = `${postalCode} ${locality}, ${country}`
		if (route) {
			address = `${route}, ${address}`
		}

		this.fieldTarget.value = address
		this.gpsInfosTarget.textContent = address
	}

	locationChanged() {
		const place = this.autocomplete().getPlace()

		if (!place.geometry) {
			// User entered the name of a Place that was not suggested and
			// pressed the Enter key, or the Place Details request failed.
			window.alert("No details available for input: '" + place.name + "'");
			return;
		}

		if (this.hasZip_codeTarget && this.hasCityTarget) {
			// Find city and postcode
			this.setCityPostcode(place.address_components)
		}

		if (!this.hasZip_codeTarget || isHiddenV2(this.zip_codeTarget)) {
			this.insertZipCode(place.address_components)
		}

		if (this.hasLatitudeTarget && this.hasLongitudeTarget) {
			this.latitudeTarget.value = place.geometry.location.lat()
			this.longitudeTarget.value = place.geometry.location.lng()
		}
	}

	setCityPostcode(components) {
		for (const component of components) {
			const componentType = component.types[0]

			switch (componentType) {
				case "postal_code": {
					this.zip_codeTarget.value = component.long_name
					break
				}
				case "locality": {
					this.cityTarget.value = component.long_name
					break
				}
			}
		}
	}

	insertZipCode(components) {
		const values = this.fieldTarget.value.split(', ')
		if (/\d/.test(values.at(-2))) return

		const postal_code = components.find(comp => comp.types[0] === 'postal_code')?.long_name
		if (!postal_code) return
		
		const indexOfCity = values.indexOf(values.at(-2))
		values[indexOfCity] = `${postal_code} ${values[indexOfCity]}`
		this.fieldTarget.value = values.join(', ')
	}


	setGpsInfos(key) {
		this.gpsInfosTarget.textContent = this.gpsInfosTarget.dataset[key]
	}


	latLngFilled() {
		return this.longitudeTarget.value && this.latitudeTarget.value
	}

	preventSubmit(e) {
		if (e.key == "Enter") { e.preventDefault() }
	}
}