import { Controller } from "@hotwired/stimulus"
import { initDateRangePicker } from "../utils/flatpickr";
import weekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect.js'
import { endOfDay, endOfWeek, startOfWeek, subWeeks, addWeeks, parse, subYears } from "date-fns";
import { invalidDate } from "../utils/datetime";

const endOfLastWeek = () => endOfWeek(subWeeks(endOfDay(new Date()), 1), { weekStartsOn: 1 })

export default class extends Controller {
  static targets = ['startDate', 'endDate', 'dateRange', 'buttonNext']
  static values = {
    locale: String,
    minDate: Number,
    maxDate: Number,
    finishedWeek: Boolean,
    appendTo: Boolean
  }

  connect() {
    let initialDate = parse(this.startDateTarget.value, 'yyyy-MM-d', new Date())
    this.minMaxDates()
    this.setWeekRangePicker()

    if(invalidDate(initialDate)) { initialDate = startOfWeek(this.maxDate, { weekStartsOn: 1 }) }

		const dispatch = !!this.element.dataset.initDispatch || false
    this.updateWeekRange([initialDate], dispatch)
  }

  setWeekRangePicker() {
    const options = {
      locale: this.localeValue,
      dateFormat: "d/m/Y",
      minDate: this.minDate,
      maxDate: this.maxDate,
      plugins: [weekSelect({})],
      onChange: (selectedDates, dateStr, instance) => {
        this.updateWeekRange(selectedDates);
        instance.close();
      }
    }

    if (this.appendToValue) {
      options.appendTo = this.dateRangeTarget.closest('.date-input__container')
    }

    this.weekRangePicker = initDateRangePicker(this.dateRangeTarget, options)
  }

  minMaxDates() {
    this.finishedWeek = this.finishedWeekValue || false
    this.minDate = subYears(new Date(), 1)
    this.maxDate = this.finishedWeek ? endOfLastWeek() : endOfWeek(new Date(), { weekStartsOn: 1 })

    if (this.hasMinDateValue && this.minDateValue) {
      const minDate = new Date(this.minDateValue)
      if (minDate > this.minDate ) { this.minDate = minDate }
    }

    if (this.hasMaxDateValue && this.maxDateValue) {
      const maxDate = new Date(this.maxDateValue)
      if (maxDate < this.maxDate) { this.maxDate = maxDate }
    }
  }

  previousWeek() {
    this.updateWeekRange([subWeeks(this.weekRangePicker.selectedDates[0], 1)])
  }

  nextWeek() {
    this.updateWeekRange([addWeeks(this.weekRangePicker.selectedDates[0], 1)])
  }

  updateFormField({ startDate, endDate }) {
    this.startDateTarget.value = startDate

    if (this.hasEndDateTarget) {
      this.endDateTarget.value = endDate
    }
  }

  updateWeekRange(selectedDates,  dispatch = true) {
    const startDate = startOfWeek(selectedDates[0], { weekStartsOn: 1 })
    const endDate = endOfWeek(selectedDates[0], { weekStartsOn: 1 })

    if (this.hasButtonNextTarget) {
      if (endDate >= this.maxDate) {
        this.buttonNextTarget.setAttribute("disabled", true)
      } else {
        this.buttonNextTarget.removeAttribute("disabled")
      }
    }

    this.weekRangePicker.config.mode = "range";
    this.weekRangePicker.setDate([startDate, endDate])
    this.updateFormField({startDate, endDate})

    if (dispatch) {
      this.dispatch('change', {detail: {startDate, endDate}})
    }
  }
}
