import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils/display";

const standardize = (string) => {
  return string.toLowerCase().trim();
};

const filter = (items, filters) => {
  for (const item of items) {
    show(item);

    for (const filter in filters) {
      const filterValue = filters[filter];
      const itemValue = item.dataset[filter];

      if (typeof filterValue === "string") {
        if (
          filterValue.length &&
          !standardize(itemValue).includes(standardize(filterValue))
        ) {
          hide(item);
        }
      } else if (typeof filterValue === "object") {
        if (filterValue.length && !filterValue.includes(itemValue)) {
          hide(item);
        }
      }
    }
  }
};

export default class extends Controller {
  static targets = ["item", "dropdown", "counter"];

  connect() {
    this.filters = {};
    document.addEventListener("click", (e) => this.closeDropdowns(e));
    document.addEventListener("cardAdded", (e) => this.count(e));
  }

  check(e) {
    const checkbox = e.target;
    const filterType = checkbox.dataset.filter;
    const filterValue = checkbox.dataset.value;
    const currentFilter = this.filters[filterType] || [];
    if (checkbox.checked) {
      this.filters[filterType] = [...currentFilter, filterValue];
    } else {
      this.filters[filterType] = currentFilter.filter(
        (item) => item !== filterValue
      );
    }
    filter(this.itemTargets, this.filters);
  }

  search(e) {
    const input = e.target;
    const filterType = input.dataset.filter;
    const filterValue = input.value;
    this.filters[filterType] = filterValue;
    filter(this.itemTargets, this.filters);
  }

  toggleDropdown(e) {
    const dropdown = this.dropdownTargets.find((el) => el.contains(e.target));
    dropdown.classList.toggle("is-open");
  }

  closeDropdowns(e) {
    for (const dropdown of this.dropdownTargets) {
      if (!dropdown.contains(e.target)) {
        dropdown.classList.remove("is-open");
      }
    }
  }

  count(e) {
    for (const counter of this.counterTargets) {
      const type = counter.dataset.filter;
      const value = counter.dataset.value;
      const number = this.itemTargets.filter(
        (item) => item.dataset[type] === value
      ).length;
      counter.innerHTML = number;
    }
  }
}
