import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['currentPage']

  connect() {
    const currentPage = this.currentPageTarget.textContent
    const searchParams = new URLSearchParams(window.location.search)

    if (currentPage != searchParams.get('page')) {
      this.dispatch('change',{detail: {currentPage}})
    }
  }
}
