import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const { type, target } = this.element.dataset

    if (type === 'pollutantHistory' || type === 'windDirPollutant' ) {
      this.dispatch('ready')
      this.dispatch('fetchHistory', { detail: { chartId: this.element.id, code: this.element.dataset.code}})
    }

    if (type === 'co2_realtime') {
      this.dispatch('realtimeReady')
    }

    if (type === 'outdoorHistory') {
      this.dispatch('outdoorReady')
      if (target === 'main_chart') {
        this.dispatch('mainChartReady')
      }
    }
  }

  ready({ currentTarget }) {
    if (currentTarget.id === 'chart-main_chart') {
      this.dispatch('mainChartReady')
    }

    if (currentTarget.id === 'chart-compared_chart') {
      this.dispatch('fetchHistory', { detail: { chartId: currentTarget.id, code: currentTarget.dataset.code}})
    }
  }
}