import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // this.showTab(0);
  }

  changeTab(event) {
    event.preventDefault();
    const clickedTab = event.currentTarget;
    const index = parseInt(clickedTab.dataset.index);
    this.showTab(index);
  }

  showTab(index) {
    const tabs = this.element.querySelectorAll(".nav-link");
    const tabPanels = this.element.querySelectorAll(".tab-panel");

    tabs.forEach((tab, i) => {
      tab.classList.toggle("is-active", i === index);
    });

    tabPanels.forEach((panel, i) => {
      if (i === index) {
        panel.classList.remove("is-hidden");
      } else {
        panel.classList.add("is-hidden");
      }
    });
  }
}