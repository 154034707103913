import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['circle', 'circleContainer'];

  connect() {
    const initialPercent = this.circleContainerTarget.dataset.completion

    if (initialPercent === '100') {
      this.circleContainerTarget.classList.add('is-complete')
    } else {
      this.setProgress(initialPercent)
    }
  }

  setProgress(percent) {
    const circle = this.circleTarget
    const circumference = parseFloat(circle.getAttribute('stroke-dasharray'))
    const dashOffset = circumference - (percent / 100) * circumference

    circle.setAttribute('stroke-dashoffset', dashOffset)
  }
}