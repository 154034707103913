import { Controller } from "@hotwired/stimulus"
import { show, hide, isHidden } from "../utils/display";
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ['companiesList']

  connect() {
    useClickOutside(this)
  }

  toggle() {
    isHidden(this.companiesListTarget) ? show(this.companiesListTarget) : hide(this.companiesListTarget)
  }

  close(event) {
    event.preventDefault()
    hide(this.companiesListTarget)
  }
}