import { Controller } from '@hotwired/stimulus'
import { initDateRangePicker } from '../utils/flatpickr'
import { hideV2, showV2 } from '../utils/display'
import { endOfDay, subYears, addYears, parse, endOfWeek } from 'date-fns'

export default class extends Controller {
	static targets = ['dateInput', 'clearButton'];

	static values = {
    locale: String,
    minDate: Number,
    maxDate: Number,
    futureDates: Boolean,
    passedDates: Boolean
  }

	connect() {
    let initialDate
    this.initialDateValue = this.element.querySelector('input')?.value
    if (this.initialDateValue) { initialDate = this.initializeDate() }
		this.setDatePicker(initialDate)

		const dispatch = !!this.element.dataset.initDispatch || false
    if (dispatch) { this.dispatchChange(initialDate) }

    if (this.element.dataset.required = !'false') {
		  showV2(this.clearButtonTarget)
    }
  }

	clearSelect() {
		hideV2(this.clearButtonTarget)
		this.datePicker.clear()
	}

  setDatePicker(initialDate) {
    if (this.hasDateInputTarget) {
      const { defaultMinDate, defaultMaxDate } = this.minMaxDate()
      const options = {
        locale: this.localeValue,
        mode: 'single',
        dateFormat: "d/m/Y",
        minDate: defaultMinDate,
        maxDate: defaultMaxDate,
        allowInput:true,
        onOpen: (selectedDates, dateStr, instance) => {
          instance.input.readOnly = true;
        },
        onClose: (selectedDates, dateStr, instance) => {
            instance.input.readOnly = false;
            instance.input.blur();
        },
        onValueUpdate: (selectedDates, dateStr, instance) => {
          this.dispatchChange(selectedDates);
        }
      }

      if (initialDate) {
        options.defaultDate = initialDate
      }

			this.datePicker = initDateRangePicker(this.dateInputTarget, options)
		}
  }

  minMaxDate() {
    let defaultMinDate = this.passedDatesValue ? subYears(new Date(), 3) : new Date()
    let defaultMaxDate = this.futureDatesValue ? addYears(new Date(), 2) : new Date()
    if (this.hasCreationDatesValue) {
      this.minDates = JSON.parse(this.creationDatesValue)
    }

    if (this.hasMinDateValue && this.minDateValue) {
      const minDate = new Date(this.minDateValue)
      if (minDate > defaultMinDate ) { defaultMinDate = minDate }
    }

    if (this.hasMaxDateValue && this.maxDateValue) {
      const maxDate = endOfDay(new Date(this.maxDateValue))
      if (maxDate < defaultMaxDate ) { defaultMaxDate = maxDate }
    }

    return {defaultMinDate, defaultMaxDate}
  }

  setMinMaxDate({detail}) {
    for (const key in detail) {
      detail[key] = detail[key] ? new Date(detail[key]) : null
    }
    const { startDate, endDate, initialDate } = detail
    startDate.setHours(0,0,0,0)
    endDate.setHours(23,59,59,0)
		this.setDatePicker(initialDate)
    this.datePicker.config.enable = [{from: startDate, to: endDate}]
    this.datePicker.jumpToDate((initialDate || endDate), true)
  }
  
  reset({detail}) {
    const { value } = detail
    let { defaultMinDate, defaultMaxDate } = this.minMaxDate()
    const initialDate = this.initialDateValue ? this.initializeDate() : null
    
    if (value === 'pando2') {
      defaultMinDate = new Date(document.querySelector('#evaluation_week').value)
      defaultMaxDate = endOfWeek(defaultMinDate, { weekStartsOn: 1 })
    }

    this.setMinMaxDate({detail: {startDate: defaultMinDate, endDate: defaultMaxDate, initialDate}})
  }

  dispatchChange(value) {
    this.dispatch('change', { detail: { value }})
  }

  initializeDate() {
    if (this.initialDateValue.match(/\//)) {
      const dateParts = this.initialDateValue.split("/")
      return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
    } else {
      return new Date(this.initialDateValue)
    }
  }
}
