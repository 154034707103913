import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";
import { initDateRangePicker } from "../../utils/flatpickr";

export default class extends Controller {
  static targets = ['chart', 'dateRange'];

  initialize() {
    this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
    this.chart.render();
  }

  connect() {
    this.dateRangePicker = initDateRangePicker({
      target: this.dateRangeTarget,
      locale: this.dataset.locale,
      appendTo: this.dateRangeTarget.parentNode,
      onChange: (selectedDates) => this.synchronizeDateChange(startOfDay(selectedDates[0]), endOfDay(selectedDates[1]))
    });

    this.synchronizeDateChange(...this.defaultDates);
  }


  defaultConfigurations() {
    return {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        export: {
          csv: {
            filename: 'co2_suspicion',
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: 'co2_suspicion',
          },
          png: {
            filename: 'co2_suspicion',
          }
        },
        // autoSelected: 'zoom'
    }
  }

  get chartOptions() {
    return {
      series: [
        {
          name: 'Lundi',
          data: this.generateDayWiseTimeSeries(400, 1300, 25)
        },
        {
          name: 'Mardi',
          data: this.generateDayWiseTimeSeries(400, 1300, 25)
        },
        {
          name: 'Mercredi',
          data: this.generateDayWiseTimeSeries(400, 1300, 25)
        },
        {
          name: 'Jeudi',
          data: this.generateDayWiseTimeSeries(400, 1300, 25)
        },
        {
          name: 'Vendredi',
          data: this.generateDayWiseTimeSeries(400, 1300, 25)
        },
        {
          name: 'Samedi',
          data: this.generateDayWiseTimeSeries(400, 1300, 25)
        },
        {
          name: 'Dimanche',
          data: this.generateDayWiseTimeSeries(400, 1300, 25)
        },
      ],
        chart: {
        height: 350,
        type: 'heatmap',
        toolbar: this.defaultConfigurations(),
      },
      plotOptions: {
        heatmap: {
          enableShades: false,
          // shadeIntensity: 0.2,
          radius: 0,
          colorScale: {
            ranges: [{
                from: 400,
                to: 500,
                name: 'very good',
                color: '#00c200'
              },
              {
                from: 501,
                to: 600,
                name: 'good',
                color: '#b9cb00'
              },
              {
                from: 601,
                to: 700,
                name: 'average+',
                color: '#e8ce00'
              },
              {
                from: 701,
                to: 900,
                name: 'average-',
                color: '#f8a822'
              },
              {
                from: 901,
                to: 1200,
                name: 'bad',
                color: '#f17e46'
              },
              {
                from: 1201,
                to: 10000,
                name: 'very bad',
                color: '#FF3E56'
              }
            ]
          }
        }
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1
      },
      xaxis: {
        labels: {
          style: {
            colors: ['#848894']
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: ['#848894']
          }
        }
      }
    }
  }

  generateDayWiseTimeSeries(min, max, count) {
    min = Math.ceil(min);
    max = Math.floor(max);

    let x = 0;
    let series = [];

    while (x < count) {
      let val = Math.floor(Math.random() * (max - min + 1)) + min;
      series.push({x, y:val});
      x++;
    }

    return series;
  }
}
