import { Controller } from "@hotwired/stimulus"

const buildRow = (label, index) => {
  const row = document.createElement("a")

  row.tabIndex = 0
  row.className = "a-listbox-item"
  row.innerText = label
  row.setAttribute("role", "button")
  row.setAttribute("data-index", index)

  return row
}

const refresh_select = (select) => {
  select.selected.innerHTML     = ""
  select.nonSelected.innerHTML  = ""
  select.selectableItemIndex    = []
  select.selectedItemIndex      = []

  const query = select.search.value

  for (let i = 0; i < select.options.length; i++) {
    const option = select.options[i]
    const label = option.textContent || option.innerText
    const row = buildRow(label, i)

    if (option.selected) {
      row.setAttribute("data-action", "dual-listbox#remove")
      select.selected.appendChild(row)
      select.selectedItemIndex.push(i)
    } else if (!query || label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
      row.setAttribute("data-action", "dual-listbox#add")
      select.nonSelected.appendChild(row)
      select.selectableItemIndex.push(i)
    }
  }

  select.selectedCount.textContent = `(${select.selectedItemIndex.length})`
  select.nonSelectedCount.textContent = `(${select.selectableItemIndex.length})`
}

export default class extends Controller {
  static targets = ["select", "leftBox", "rightBox", "search", "selectedItemCount", "selectableItemCount"]

  connect() {
    this.selectTarget.search      = this.searchTarget
    this.selectTarget.selected    = this.rightBoxTarget
    this.selectTarget.nonSelected = this.leftBoxTarget
    this.selectTarget.selectedCount = this.selectedItemCountTarget
    this.selectTarget.nonSelectedCount = this.selectableItemCountTarget
    refresh_select(this.selectTarget)
  }

  add(event) {
    this.selectTarget.options[event.target.dataset.index].selected = true
    refresh_select(this.selectTarget)
  }

  remove(event) {
    this.selectTarget.options[event.target.dataset.index].selected = false
    refresh_select(this.selectTarget)
  }

  addAll() {
    this.selectTarget.selectableItemIndex.forEach(i => this.selectTarget.options[i].selected = true)
    refresh_select(this.selectTarget)
  }

  removeAll() {
    this.selectTarget.selectedItemIndex.forEach(i => this.selectTarget.options[i].selected = false)
    refresh_select(this.selectTarget)
  }

  search() {
    refresh_select(this.selectTarget)
  }
}