import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.element.classList.add("is-checked");
    } else {
      this.element.classList.remove("is-checked");
    }
  }
}