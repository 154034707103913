import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal', 'overlay', 'ignoreButton', 'leaveModal', 'leaveOverlay', 'migrateButton', 'step1NextButton', 'copyCheckbox']

  connect() {
    if (this.hasCopyCheckboxTarget) {
      this.copyCheckboxTarget.checked = false
    }
  }

  openModal() {
    this.modalTarget.classList.add('is-open');
    this.overlayTarget.classList.add('is-open');
  }

  closeModal() {
    this.modalTarget.classList.remove('is-open');
    this.overlayTarget.classList.remove('is-open');
  }

  showLeaveModal() {
    open(this.leaveModalTarget)
    open(this.leaveOverlayTarget)
  }

  closeLeaveModal() {
    close(this.leaveModalTarget)
    close(this.leaveOverlayTarget)
  }

  showMigrate() {
    this.ignoreButtonTarget.classList.add('is-hidden')
    this.migrateButtonTarget.classList.remove('is-hidden')
  }

  showIgnore() {
    this.migrateButtonTarget.classList.add('is-hidden')
    this.ignoreButtonTarget.classList.remove('is-hidden')
  }

  toggleStep1NextUrl(event) {
    const button = this.step1NextButtonTarget
    const url = event.target.checked ? button.dataset.copyUrl : button.dataset.url
    button.href = url
  }
}