import { Controller } from "@hotwired/stimulus"
import { open, close, showV2, hideV2 } from "../utils/display"

export default class extends Controller {
  static targets = [
    'panel',
    'overlay',
    'resultItem',
    'searchResult',
    'companyList',
    'clearButton',
    'searchInput'
  ]

  open() {
    if (this.hasPanelTarget) { 
      open(this.panelTarget)
      open(this.overlayTarget) 
    }
  }

  close() {
    close(this.panelTarget)
    close(this.overlayTarget)
    this.clearSearch()
  }

  search(event) {
    const { value } = event.currentTarget
    if (value) {
      const items = this.resultItemTargets.filter(item =>
        item.getElementsByTagName('a')[0].innerHTML.toLowerCase().includes(value.toLowerCase())
      )
      hideV2(...this.resultItemTargets)
      hideV2(this.companyListTarget)
      showV2(this.searchResultTarget)
      showV2(this.clearButtonTarget)
      showV2(...items)
    } else {
      showV2(this.companyListTarget)
      hideV2(this.searchResultTarget)
      hideV2(this.clearButtonTarget)
      hideV2(...this.resultItemTargets)
    }
  }

  clearSearch() {
    this.searchInputTarget.value = ''
    showV2(this.companyListTarget)
    hideV2(this.searchResultTarget)
    hideV2(this.clearButtonTarget)
    hideV2(...this.resultItemTargets)
  }
}