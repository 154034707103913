import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static targets = ["chart"];

  initialize() {
    document.addEventListener("DOMContentLoaded", () => {
      const elem = this.chartTarget;
      this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
      this.chart.render();
    });
  }

  get chartOptions() {
    return {
      chart: {
        type: 'bar',
        height: '224px',
        // stacked: true,
      },
      series: [{
        name: 'PM10',
        data: this.generateDayWiseTimeSeries(0, 72)
      }],
      xaxis: {
        type: "datetime",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        title: {
          text: 'μg/m³'
        }
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy HH:mm"
        },
      },
      fill: {
        type: "solid",
        fillOpacity: 0.7
      },
      // colors: ["#00D61F", "#FFD900", "#FF3E56", "#848894"],
      // xaxis: {
      //   categories: ['00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h'],
      // },
      // legend: {
      //   show: false,
      // },
      dataLabels: {
        enabled: false
      },
    }
  }

  generateDayWiseTimeSeries(s, count) {
    var i = 0;
    var series = [];
    var x = new Date().getTime();

    while (i < count) {
      var val = Math.floor(Math.random() * 70) + 1;
      var color = this.applyColorState(val);
      series.push({x, y:val, strokeColor:color.strokeColor, fillColor:color.fillColor});
      x -= 600000;
      i++;
    }

    return series;
  }

  applyColorState(value) {
    var color = null;
    var good = {'strokeColor': '#F5FFF5', 'fillColor': '#00C200'};
    var intermediate = {'strokeColor': '#FFFDF5', 'fillColor': '#FFD900'};
    var bad = {'strokeColor': '#FFF5F5', 'fillColor': '#FF3E56'};

    // var good = {'strokeColor': '#00D61F', 'fillColor': '#00D61F'};
    // var intermediate = {'strokeColor': '#FFD900', 'fillColor': '#FFD900'};
    // var bad = {'strokeColor': '#FF3E56', 'fillColor': '#FF3E56'};

    if (value < 35)
      color = good;
    else if (value < 50)
      color = intermediate;
    else
      color = bad;

    return color;
  }
}
