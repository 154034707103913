import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['circle', "circleContainer"];

  connect() {
    const circleContainer = this.circleContainerTarget;
    const initialPercent = circleContainer.getAttribute("data-progress-percent");

    this.setProgress(initialPercent);
  }

  setProgress(percent) {
    const circle = this.circleTarget;

    const circumference = parseFloat(circle.getAttribute('stroke-dasharray'));
    const dashOffset = circumference - (percent / 100) * circumference;

    circle.setAttribute('stroke-dashoffset', dashOffset);
  }
}