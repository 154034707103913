import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["parent"];

  initialize() {
    const events = JSON.parse(this.data.get("events"));
    this.addEvent = events.add;
    this.removeEvent = events.remove;
  }

  connect() {
    document.addEventListener(this.addEvent, (event) => this.add(event));
    document.addEventListener(this.removeEvent, () => this.remove());
  }

  disconnect() {
    document.removeEventListener(this.addEvent, (event) => this.add(event));
    document.removeEventListener(this.removeEvent, () => this.remove());
  }

  add(event) {
    const position = event.detail.position;
    const text = event.detail.text;
    this.parentTarget.innerHTML = "";
    this.parentTarget.insertAdjacentHTML(position, text);
  }

  remove() {
    this.parentTarget.innerHTML = "";
  }
}
