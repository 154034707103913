import Rails from "rails-ujs";

export const requestChart = (form, onSuccess, onError) => {
  return Rails.ajax({
    url: form.action,
    type: "post",
    dataType: "json",
    data: new FormData(form),
    success: onSuccess,
    error: onError
  });
};
