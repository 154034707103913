import { showV2, hideV2 } from "../utils/display";

export const AsyncBox = (name, loader, box) => {
  const loading = () => {
    hideV2(box);
    showV2(loader);
  };
  const loaded = () => {
    showV2(box);
    hideV2(loader);
  };

  const error = () => {
    return
  }

  return { loading, loaded, error };
};
