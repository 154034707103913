import { Controller } from "@hotwired/stimulus"
import { open, close } from "../utils/display"

export default class extends Controller {
  static targets = ['migrateModal', 'migrateOverlay', 'dissociateModal', 'dissociateOverlay', 'deleteModal', 'deleteOverlay', 'associateModal', 'associateOverlay']

  showMigrateModal() {
    open(this.migrateModalTarget)
    open(this.migrateOverlayTarget)
  }

  closeMigrateModal() {
    close(this.migrateModalTarget)
    close(this.migrateOverlayTarget)
  }

  showDissociateModal() {
    open(this.dissociateModalTarget)
    open(this.dissociateOverlayTarget)
  }

  closeDissociateModal() {
    close(this.dissociateModalTarget)
    close(this.dissociateOverlayTarget)
  }

  showDeleteModal() {
    open(this.deleteModalTarget)
    open(this.deleteOverlayTarget)
  }

  closeDeleteModal() {
    close(this.deleteModalTarget)
    close(this.deleteOverlayTarget)
  }

  showAssociateModal() {
    open(this.associateModalTarget)
    open(this.associateOverlayTarget)
  }

  closeAssociateModal() {
    close(this.associateModalTarget)
    close(this.associateOverlayTarget)
  }
}