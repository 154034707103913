import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ['filename']
  
  updateFileName({detail: {filename}}) {
    const trimedFilename = filename.substring(0, filename.lastIndexOf('.')) || filename
    this.filenameTarget.value = trimedFilename
  }
}