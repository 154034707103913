import { Controller } from "@hotwired/stimulus"
import { close } from "../utils/display"


export default class extends Controller {
  static targets = ["panel", "filter"]

  clear() {
    this.filterTargets.forEach(filter => filter.checked = false)
  }

  submit() {
    const selectedFilters = this.filterTargets.filter(filter => filter.checked).reduce(
      (acc, filter) => {
        (acc[filter.dataset.group] = acc[filter.dataset.group] || []).push(filter.name)
        return acc
      }, {}
    )
    close(this.panelTarget)
  }

  remove(event) {
    event.currentTarget.parentElement.remove()
  }
}