import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils/display";

const selectedRadioValue = (radioButtons) => {
  return radioButtons.filter((selection) => {
    return selection.checked;
  })[0].value;
};

export default class extends Controller {
  static targets = ["selection", "establishments", "rooms", "floors"];

  connect() {
    if (selectedRadioValue(this.selectionTargets) === "Establishment") {
      show(this.establishmentsTargets);
    } else if (selectedRadioValue(this.selectionTargets) === "Room") {
      show(this.roomsTargets);
      hide(this.establishmentsTargets);
    } else if (selectedRadioValue(this.selectionTargets) === "Floor") {
      show(this.floorsTargets);
      hide(this.establishmentsTargets);
    }
  }

  onAreaTypeChange() {
    if (selectedRadioValue(this.selectionTargets) === "Establishment") {
      show(this.establishmentsTargets);
      hide(this.roomsTargets);
      hide(this.floorsTargets);
    } else if (selectedRadioValue(this.selectionTargets) === "Room") {
      hide(this.establishmentsTargets);
      show(this.roomsTargets);
      hide(this.floorsTargets);
    } else if (selectedRadioValue(this.selectionTargets) === "Floor") {
      hide(this.establishmentsTargets);
      hide(this.roomsTargets);
      show(this.floorsTargets);
    }
  }

  establishmentChange(event) {
    event.preventDefault()
    if (event.detail.field == 'establishment_id') {
      const url = `${this.element.dataset.establishmentUrl}?${new URLSearchParams({establishment_id: event.detail.value})}`

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'text/vnd.turbo-stream.html'
        }
      })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
    }
  }
}
