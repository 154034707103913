import ApexCharts from 'apexcharts';
import moment from 'moment';

const B500 = '#397ee6';
const BAD = '#FF3E56';
const INTERMEDIATE = '#FFD900';
const GOOD = '#00D61F';
const OFFLINE = '#9ca0b3';
const BORDERCOLOR = '#d3dae6';
const LABEL_COLOR = '#848894';
const DATETIME_FORMAT = 'DD/MM/yyyy HH:mm';

export const chart = (target, data) => {
  return new ApexCharts(target, getChartOptions(data));
};

const getChartOptions = ({ type, ...rest }) => {
  const OPTIONS_MAP = {
    areaHistory: areaHistoryOptions,
    co2_history: co2HistoryOptions,
    co2_realtime: co2RealtimeOptions,
    comparisonsTimeseries: comparisonsOptions,
    datatableDonut: donutDatatableOptions,
    donut: donutOptions,
    sensorDonut: sensorDonutOptions,
    headerDonut: headerDonutOptions,
    heatmap: heatmapOptions,
    pollutantDistribution: barHorizontalOptions,
    pollutantHistory: historyOptions,
    reportArea: reportAreaOption,
    reportBar: barOptions,
    reportHeatmap: reportHeatmapOptions,
    windDirPollutant: winddirHistoryOptions,
  };

  const optionFunction = OPTIONS_MAP[type];

  if (!optionFunction) {
    throw new Error(`No Apex options found for type: ${type}`);
  }

  return optionFunction({ type, ...rest });
};

const tooltipY = ({ unit, noFloat = false }) => {
  return {
    formatter: function (y) {
      if (y || y == 0) {
        return `${noFloat ? y.toFixed(0) : y} ${unit}`;
      }
      return 'n/a';
    },
  };
};

const checkIsChrome = () => {
  return (
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  );
};

const animations = () => {
  if (checkIsChrome()) {
    return {
      enabled: false,
    };
  }

  return {
    enabled: true,
    easing: 'easeinout',
    speed: 350,
  };
};

const headerDonutOptions = ({ series, colors, labels }) => {
  return {
    chart: { type: 'donut', height: '60px', width: '60px' },
    series: JSON.parse(series),
    colors: JSON.parse(colors),
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'round',
      width: 0,
      dashArray: 0,
    },
    labels: JSON.parse(labels),
    legend: { show: false },
    dataLabels: { enabled: false },
    grid: {
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
    },
    plotOptions: {
      pie: {
        donut: { size: '80%' },
      },
    },
  };
};

const donutOptions = ({ series, colors, labels, unit, id }) => {
  return {
    chart: { id, type: 'donut', height: '140px', width: '140px' },
    series: JSON.parse(series),
    colors: JSON.parse(colors),
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'round',
      width: 0,
      dashArray: 0,
    },
    labels: JSON.parse(labels),
    legend: { show: false, position: 'bottom' },
    dataLabels: { enabled: false },
    grid: {
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
    },
    plotOptions: {
      pie: {
        donut: { size: '80%' },
      },
    },
    tooltip: { y: tooltipY({ unit }) },
  };
};

const sensorDonutOptions = ({ series, colors, labels }) => {
  return { ...donutOptions({ series, colors, labels }), tooltip: { enabled: false } }
};

export const comparisonsOptions = ({
  group,
  chart,
  id,
  unit,
  series,
  thresholds,
  spaceName,
}) => {
  const basicOptions = historyBasicOptions({
    series,
    unit,
    height: '300px',
    type: chart,
    filename: `${spaceName}_comparaison`,
  });

  return {
    ...basicOptions,
    chart: {
      ...basicOptions.chart,
      group,
      id,
      redrawOnParentResize: true,
    },
    stroke: { curve: 'straight', width: 2 },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      itemMargin: { vertical: 15 },
    },
    annotations: {
      yaxis: JSON.parse(thresholds).map((threshold) => ({
        y: threshold.value,
        strokeDashArray: 5,
        borderWidth: 3,
        borderColor: threshold.color,
        label: {
          text: threshold.label,
        },
      })),
    },
    yaxis: {
      title: { text: unit },
      labels: { minWidth: 45, offsetX: -20 },
      tooltip: { enabled: false },
      min: 0,
    },
  };
};

const heatmapOptions = ({ series, legendMin, legendMax, spaceName }) => {
  return {
    series: JSON.parse(series),
    chart: {
      height: 350,
      type: 'heatmap',
      toolbar: toolbarExportConfiguration(`${spaceName}_co2_suspicion`),
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 350,
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        radius: 0,
        colorScale: {
          ranges: [
            { from: 0, to: 350, name: legendMin, color: OFFLINE },
            { from: 351, to: 749, name: ' ', color: '#C2F1C2' },
            { from: 750, to: 999, name: ' ', color: '#99E799' },
            { from: 1000, to: 1499, name: ' ', color: '#69DB69' },
            { from: 1500, to: 1999, name: ' ', color: '#36CF36' },
            { from: 2000, to: 10000, name: legendMax, color: '#00C200' },
          ],
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontFamily: '"hind", sans-serif',
      fontSize: '14px',
      labels: { colors: LABEL_COLOR },
      markers: { width: 15, height: 15, radius: 4 },
    },
    dataLabels: { enabled: false },
    stroke: { width: 1 },
    xaxis: {
      labels: {
        style: { colors: [LABEL_COLOR] },
        datetimeUTC: false,
        hideOverlappingLabels: true,
        formatter: function (val) {
          return moment(new Date(val)).format('HH[h]');
        },
      },
    },
    yaxis: {
      labels: {
        style: { colors: [LABEL_COLOR] },
      },
    },
    tooltip: {
      x: {
        show: true,
      },
      y: tooltipY({ unit: 'ppm' }),
    },
  };
};

export const historyBasicOptions = ({
  series,
  unit,
  height,
  type,
  filename,
}) => {
  return {
    series: JSON.parse(series),
    chart: {
      type: type || 'bar',
      height: height || 200,
      animations: animations(),
      toolbar: toolbarExportConfiguration(filename),
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
      },
    },
    xaxis: {
      type: 'datetime',
      labels: { datetimeUTC: false },
      tooltip: { enabled: false },
    },
    yaxis: {
      title: { text: unit },
      forceNiceScale: true,
      tooltip: { enabled: false },
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    grid: {
      show: true,
      borderColor: BORDERCOLOR,
      strokeDashArray: 2,
      position: 'back',
      xaxis: {
        lines: { show: false },
      },
      yaxis: {
        lines: { show: true },
      },
      padding: { left: 10, right: 5 },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: function (value) {
          return moment(new Date(value)).format(DATETIME_FORMAT);
        },
      },
      y: tooltipY({ unit }),
      shared: true,
      intersect: false,
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
  };
};

const historyOptions = ({ series, spaceName, unit, group, chartId }) => {
  const basicOptions = historyBasicOptions({
    series,
    unit,
    filename: `${spaceName}_${JSON.parse(series)[0].name}_history`,
  });

  return {
    ...basicOptions,
    chart: {
      ...basicOptions.chart,
      group,
      id: chartId,
    },
  };
};

const winddirHistoryOptions = ({ series, spaceName, unit, group, chartId }) => {
  const basicOptions = historyBasicOptions({
    series,
    unit,
    type: 'scatter',
    filename: `${spaceName}_winddir_history`,
  });

  const annotations = basicOptions.series
    .map((serie) => {
      return serie.data.map((datapoint) => ({
        x: datapoint.x,
        y: datapoint.y,
        image: {
          path: datapoint.icon,
          width: 15,
          height: 15,
          offsetY: -2,
        },
        marker: {
          size: 0,
        },
      }));
    })
    .flat();

  return {
    ...basicOptions,
    chart: {
      ...basicOptions.chart,
      group,
      id: chartId,
    },
    markers: {
      size: 0.1,
      shape: "circle",
      fillOpacity: 0,
    },
    annotations: {
      points: annotations,
    },
    tooltip: {
      ...basicOptions.tooltip,
      y: {
        formatter: function (_, { seriesIndex, dataPointIndex, w }) {
          let point = w.config.series[seriesIndex].data[dataPointIndex];
          return `${point.tooltip}`;
        },
      },
    },
  };
};

const co2HistoryOptions = ({ series }) => {
  return {
    chart: { type: 'bar', height: 300, width: '100%' },
    series: JSON.parse(series),
    legend: { show: false },
    dataLabels: { enabled: false },
    yaxis: { min: 0, max: 6, tickAmount: 6 },
    plotOptions: {
      bar: { columnWidth: '15px' },
    },
  };
};

const co2RealtimeOptions = ({ series, colors, labels }) => {
  return {
    chart: { type: 'pie', height: '192px', width: '192px' },
    series: JSON.parse(series),
    colors: JSON.parse(colors),
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'round',
      width: 1,
      dashArray: 0,
    },
    labels: JSON.parse(labels),
    legend: { show: false },
    dataLabels: { enabled: false },
  };
};

const areaHistoryOptions = ({ series, labels, unit, group, id, spaceName }) => {
  const jsonLabels = JSON.parse(labels);
  const basicOptions = historyBasicOptions({
    series,
    unit,
    height: '250px',
    type: 'area',
    filename: `${spaceName}_history`,
  });

  return {
    ...basicOptions,
    chart: {
      ...basicOptions.chart,
      id,
      group,
    },
    stroke: { curve: 'straight', width: 2 },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
    },
    xaxis: {
      categories: jsonLabels,
      axisTicks: { show: false },
      tooltip: { enabled: false },
      labels: {
        show: true,
        rotate: 0,
        datetimeUTC: true,
        hideOverlappingLabels: true,
        formatter: function (val) {
          const currentDate = moment(new Date(val));
          if (currentDate.hour() == 0 && currentDate.minute() == 0) {
            return [
              currentDate.format('HH[h]'),
              currentDate.format('DD/MM/yyyy'),
            ];
          }
          return currentDate.format('HH[h]');
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (y) {
          return `${y.toFixed(0)} ${unit}`;
        },
      },
      x: {
        formatter: function (val) {
          return moment(new Date(jsonLabels[val - 1])).format(DATETIME_FORMAT);
        },
      },
    },
  };
};

const barHorizontalOptions = ({ series, labels, colors, unit }) => {
  return {
    chart: {
      type: 'bar',
      height: 300,
      stacked: true,
    },
    series: JSON.parse(series),
    colors: JSON.parse(colors),
    xaxis: {
      categories: JSON.parse(labels),
    },
    tooltip: {
      y: {
        formatter: function (y) {
          return `${y.toFixed(0)} ${unit}`;
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true },
    },
    dataLabels: { enabled: false },
    fill: { opacity: 1 },
    legend: { show: false },
  };
};

const donutDatatableOptions = ({ colors, labels, series }) => {
  return {
    chart: {
      type: 'donut',
      width: 54,
      height: 54,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      width: 0,
    },
    grid: {
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
    },
    plotOptions: {
      pie: {
        donut: { size: '70%' },
      },
    },
    series: JSON.parse(series),
    colors: JSON.parse(colors),
    labels: JSON.parse(labels),
  };
};

const toolbarExportConfiguration = (filename) => {
  return {
    show: true,
    offsetX: 0,
    offsetY: 0,
    tools: {
      download: true,
      selection: true,
      zoom: true,
      zoomin: true,
      zoomout: true,
      pan: false,
      reset: true,
      customIcons: [],
    },
    export: {
      csv: {
        headerCategory: 'Date',
        dateFormatter: function (timestamp) {
          return moment(new Date(timestamp)).format(DATETIME_FORMAT);
        },
        filename: filename,
        columnDelimiter: ',',
        headerCategory: 'category',
        headerValue: 'value',
      },
      svg: { filename: filename },
      png: { filename: filename },
    },
  };
};

const barOptions = ({ series, labels }) => {
  const parsedSeries = JSON.parse(series).map((s, _seriesIndex) => {
    const backgroundColors = s.backgroundColor || [];
    const dataPoints = s.data.map((dataPoint, index) => ({
      x: labels[index],
      y: dataPoint,
      fillColor: backgroundColors[index] || '#9ca0b3',
    }));

    return {
      ...s,
      data: dataPoints,
      colors: backgroundColors.length > 0 ? backgroundColors : ['#9ca0b3'],
    };
  });

  return {
    series: parsedSeries,
    chart: {
      height: 350,
      type: 'bar',
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '70%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
    legend: {
      position: 'bottom',
      fontSize: '10px',
    },
    xaxis: {
      categories: JSON.parse(labels),
      labels: {
        rotate: -90,
        style: {
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '10px',
        },
      },
    },
    fill: {
      opacity: 1,
      type: 'solid',
    },
    colors: parsedSeries.flatMap((s) => s.colors),
  };
};

const reportHeatmapOptions = ({ series, ranges }) => {
  return {
    series: JSON.parse(series),
    chart: {
      height: 350,
      width: 700,
      type: 'heatmap',
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        radius: 0,
        colorScale: {
          ranges: JSON.parse(ranges),
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    legend: {
      position: 'bottom',
      fontSize: '10px',
      markers: {
        radius: 0,
      },
    },
    xaxis: {
      labels: {
        rotate: -90,
        style: {
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '10px',
        },
      },
    },
  };
};

const reportAreaOption = ({ series, labels }) => {
  const parsedSeries = JSON.parse(series).map((s) => ({
    ...s,
    color: s.borderColor,
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: s.backgroundColor,
            opacity: 0.7,
          },
          {
            offset: 100,
            color: s.backgroundColor,
            opacity: 0.9,
          },
        ],
      },
    },
  }));

  return {
    series: parsedSeries,
    chart: {
      type: 'area',
      height: '100%',
      toolbar: {
        show: false,
      },
    },
    labels: JSON.parse(labels),
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: [2, 2],
    },
    tooltip: {
      enabled: false,
      shared: false,
      intersect: false,
    },
    legend: {
      position: 'bottom',
      formatter: function (_seriesName, opts) {
        return opts.w.config.series[opts.seriesIndex].label;
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Math.floor(value) === value ? `${value}` : '';
        },
      },
      forceNiceScale: true,
    },
    xaxis: {
      type: 'category',
      categories: JSON.parse(labels),
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
};
