import { Controller } from "@hotwired/stimulus"
import { open, close, isOpened } from "../utils/display"

export default class extends Controller {
  static targets = ["panel", "buttonSubmit", "card"]

  toggle() {
    isOpened(this.panelTarget) ? close(this.panelTarget) : open(this.panelTarget)
  }

  close() {
    close(this.panelTarget)
    close(this.filterTarget)
  }

  open() {
    open(this.panelTarget)
    open(this.filterTarget)
  }

  toggleFullWidth(event) {
    const clickedCard = event.currentTarget.closest("[data-analyse-target='card']");
    clickedCard.classList.toggle("is-full");
  
    const svgIcon = clickedCard.querySelector(".toggle-icon");

    if (clickedCard.classList.contains("is-full")) {
      const iconContent = svgIcon.dataset.iconHide;
      svgIcon.innerHTML = iconContent;
    } else {
      const iconContent = svgIcon.dataset.iconShow;
      svgIcon.innerHTML = iconContent;
    }
  }
}