import { Controller } from "@hotwired/stimulus"
import { DateRange } from "../charts/date_range"
import {
  endOfDay,
  startOfDay,
  startOfWeek,
  endOfWeek, 
  format,
  startOfMonth,
  min,
  subDays
} from "date-fns"

const dateString = (locales, startDate, endDate, step) => {
  const dateFormat = step === 'hour' ? "dd/LL/yyyy HH:mm" : "dd/LL/yyyy"
  const { from, to, article } = locales

  if (step === 'day') {
    return `${article} ${format(startDate, dateFormat)}`
  }

  return `${from} ${format(startDate, dateFormat)} ${to} ${format(endDate, dateFormat)}`
}

const dateRangeOptions = (type) => {
  return type === 'history' ? historyOptions() : realtimeOptions()
}

const historyOptions = () => {
  const now = new Date()

  return {
    startDate: startOfMonth(now),
    endDate: endOfDay(now),
    maxDate: now
  }
}

const realtimeOptions = () => {
  const now = new Date()
  const thisWeekSaturday = subDays(endOfWeek(now, { weekStartsOn: 1 }), 1)

  return {
    startDate: startOfDay(now),
    endDate: now,
    minDate: startOfWeek(now, { weekStartsOn: 1 }),
    maxDate: min([now, thisWeekSaturday]),
  }
}

export default class extends Controller {
  static targets = [
    'dateRange',
    'startDate',
    'endDate',
    'previous',
    'next',
    'timeStep'
  ]

  initialize() {
    this.locales = JSON.parse(this.element.dataset.locales)
    this.type = this.element.dataset.type
    const options = dateRangeOptions(this.type)
    const stepSize = this.timeStepTargets.filter(element => element.checked)[0].value 
    this.timeStep = stepSize

    this.dateRange = DateRange({
      ...options,
      stepSize,
      onDateChange: this.synchronizeDateChange.bind(this),
    })
  }

  connect() {
    this.dateRange.init()
  }

  previousPeriod() {
    this.dateRange.backward()
  }

  nextPeriod() {
    this.dateRange.forward()
  }

  changeDateRange({ currentTarget }) {
    this.timeStep = currentTarget.value
    this.dateRange.step(currentTarget.value)
  }

  synchronizeDateChange(startDate, endDate, dateRange, dispatch = true) {
    this.dateRange = dateRange

    this.dateRange.hasNext()
      ? this.nextTarget.removeAttribute("disabled")
      : this.nextTarget.setAttribute("disabled", true)

    this.dateRange.hasPrevious()
      ? this.previousTarget.removeAttribute("disabled")
      : this.previousTarget.setAttribute("disabled", true)

    this.startDateTarget.value = startDate
    this.endDateTarget.value = endDate
    this.dateRangeTarget.innerHTML = dateString(this.locales, startDate, endDate, this.timeStep)

    if (dispatch) {
      this.dispatch('dateRangeChange')
    } else {
      // const form = this.type.charAt(0).toUpperCase() + this.type.slice(1)
      this.dispatch(`initForm`)
    }
  }
}
