import { Controller } from "@hotwired/stimulus"
import { show, hide, isHidden } from "../utils/display";
import { useClickOutside } from "stimulus-use"


export default class extends Controller {
  static targets = ['menu']

  connect() {
    useClickOutside(this)
  }

  toggle() {
    isHidden(this.menuTarget) ? show(this.menuTarget) : hide(this.menuTarget)
  }

  close(event) {
    event.preventDefault()
    hide(this.menuTarget)
  }
}