import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import {
    endOfDay,
    formatISO,
    isFuture,
    startOfDay,
    subDays
} from "date-fns";

export default class extends Controller {

    static targets = [
        "startDate",
        "endDate",
        "dateRange",
        "buttonTimespan",
    ];

    initialize() {
        const endDate = endOfDay(new Date());
        const startDate = startOfDay(subDays(endDate, 2));

        this.defaultDates = [startDate, endDate];
    }

    connect() {
        this.dateRangePicker = this.initDateRangePicker();

        this.synchronizeDateChange(...this.defaultDates);
    }

    initDateRangePicker() {
        return flatpickr(this.dateRangeTarget, {
            mode: "range",
            locale: this.data.get("locale"),
            dateFormat: "d/m/Y H:i",
            appendTo: this.dateRangeTarget.parentNode,
            maxDate: endOfDay(new Date()),
            onChange: (selectedDates) => {
                if (selectedDates.length == 2) {
                    const startDate = startOfDay(selectedDates[0]);
                    const endDate = endOfDay(selectedDates[1]);

                    this.buttonTimespanTargets.map(button => button.classList.remove('is-selected'))

                    this.synchronizeDateChange(startDate, endDate);
                }
            },
        });
    }

    synchronizeDateChange(startDate, endDate) {
        if (isFuture(endDate)) {
            endDate = new Date();
        }

        this.dateRangePicker.setDate([startDate, endDate]);

        this.startDateTarget.value = formatISO(startDate);
        this.endDateTarget.value = formatISO(endDate);
        this.startDateTarget.dispatchEvent(new Event("change"));
    }
}
