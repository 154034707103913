import { Controller } from "@hotwired/stimulus";
import { hideV2, showV2 } from "../utils/display";

export default class extends Controller {
  static targets = ['tab']

  select(event) {
    const navLink = event.currentTarget.getElementsByClassName('nav-link')[0]
    
    if (navLink.classList.contains('is-active')) 
    {
      event.preventDefault()
    } else {
      this.tabTargets.forEach(element => element.classList.remove('is-active'))
      navLink.classList.add('is-active')
      const activeTab = event.currentTarget.getElementsByTagName('a')[0].dataset.navTabsNameParam
      document.querySelectorAll('[data-nav-tabs-target=toHide]').forEach(element => {
        element.dataset.hideTab == activeTab ? hideV2(element) : showV2(element)
      })
    }
  }

  changeUrl(event) {
    const {currentTarget: { dataset }} = event
    if (dataset.navTabsNameParam) {
      const editButton = document.querySelector('a[data-turbo-frame=sidepanel]')
      if (editButton) {
        const url = new URL(editButton.href)
        url.searchParams.set('tab', dataset.navTabsNameParam)
        editButton.href = url.href
      }
    }

    window.history.replaceState({}, '', `?tab=${event.params.name}`)
  }
}