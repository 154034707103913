import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static targets = ["chart"];

  connect() {
    if (this.hasChartTarget) {
      const elem = this.chartTarget;
      this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
      this.chart.render();
    }
  }

  get chartOptions() {
    return {
      chart: {
        type: 'bar',
        width: 80,
        height: 44,
        stacked: true,
        sparkline: {
          enabled: true
        }
      },
      series: [{
        name: 'Good',
        data: [3, 0, 0, 0]
      }, {
        name: 'Intermediate',
        data: [0, 4, 0, 0]
      }, {
        name: 'Bad',
        data: [0, 0, 5, 0]
      }, {
        name: 'Offline',
        data: [0, 0, 0, 3]
      }],
      colors: ["#00D61F", "#FFD900", "#FF3E56", "#848894"],
      plotOptions: {
        bar: {
          borderRadius: 10,
        }
      },
      xaxis: {
        crosshairs: {
          width: 1
        },
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return ''
            }
          }
        },
        marker: {
          show: false
        }
      }
    }
  }
}