import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';

export default class extends Controller {
  connect() {
    this.initializeSwiper();
  }

  initializeSwiper() {
    if (window.innerWidth <= 768) {
      new Swiper('.swiper', {
        modules: [Navigation, Pagination],
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        paginationClickable: true,
        spaceBetween: 20,
        autoplay: {
          delay: 3000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }
  }
}
