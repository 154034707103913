import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  click(event) {
    event.preventDefault()
    const {url, method} = event.params

    fetch(url, {
      method: method || 'GET',
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      } 
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}