import { Controller } from "@hotwired/stimulus";
import { hideV2, showV2, open, close, activate, desactivate, hide } from "../utils/display";

const TO_CURRENT = 'current'

const displayedInput = (parameterSettings) => {
  const currentlyDisplayed = parameterSettings.filter(element => !element.classList.contains('is-hidden'))[0]
  return currentlyDisplayed.getElementsByTagName('input')
}

export default class extends Controller {
  static targets = [
    'parameterSelect',
    'parameterSetting',
    'parameterOption',
    'parameterToggle',
    'selected',
    'panel',
    'options',
    'actions'
  ]

  init() {
    const firstItem = this.parameterSelectTargets[0]
    this.parameterOptionTargets[0].classList.add('is-active')
    showV2(this.parameterToggleTargets[0])
    firstItem.classList.add('is-active')
    this.showSetting(firstItem.dataset.gradingParameterParam)

    if (this.hasParameterSelectTarget && this.parameterSelectTarget.dataset.gradingIsGradable === 'false') {
      hideV2(this.actionsTarget);
    }
  }

  select(event) {
    if (event.currentTarget.classList.contains('is-active')) return
    this.resetParameter(TO_CURRENT)
    this.activeParameter(event.currentTarget)
    this.showSetting(event.params.parameter)
    event.currentTarget.dataset.gradingIsGradable == 'true' ? showV2(this.actionsTarget) : hideV2(this.actionsTarget)
  }

  mobileSelect(event) {
    if (event.currentTarget.classList.contains('is-active')) return
    this.selectedTarget.innerHTML = event.currentTarget.innerHTML
    this.resetParameter(TO_CURRENT)
    this.activeOption(event.currentTarget)
    this.showToggle(event.params.parameter)
    this.showSetting(event.params.parameter)
    this.optionsTarget.classList.remove('is-open')
    event.currentTarget.dataset.gradingIsGradable == 'true' ? showV2(this.actionsTarget) : hideV2(this.actionsTarget)
  }

  toggleMobileSelect() {
    this.optionsTarget.classList.toggle('is-open')
  }

  activeParameter(parameter) {
    this.parameterSelectTargets.filter(element => element.classList.contains('is-active'))[0].classList.remove('is-active')
    parameter.classList.add('is-active')
  }

  activeOption(option) {
    this.parameterOptionTargets.filter(element => element.classList.contains('is-active'))[0].classList.remove('is-active')
    option.classList.add('is-active')
  }

  showSetting(parameter) {
    const settingParameter = this.parameterSettingTargets.filter(element => element.dataset.parameter === parameter)[0]
    this.parameterSettingTargets.forEach(element => hideV2(element))
    showV2(settingParameter)
  }

  showToggle(parameter) {
    const toggle = this.parameterToggleTargets.filter(element => element.dataset.parameter === parameter)[0]
    this.parameterToggleTargets.forEach(element => hideV2(element))
    showV2(toggle)
  }

  resetParameter(to = 'reference') {
    const inputs = displayedInput(this.parameterSettingTargets)

    for (let input of inputs) {
      input.value = to === TO_CURRENT ? input.dataset.current : input.dataset.reference
    }
  }

  saveParameter(event) {
    if (event.detail.success) {
      const inputs = displayedInput(this.parameterSettingTargets)

      for (let input of inputs) {
        input.dataset.current = input.value
      }
    }
  }

  openReference(event) {
    const panel = this.panelTargets.filter(element => element.dataset.parameter === event.params.parameter)[0]
    open(panel)
  }

  closeReference(event) {
    const panel = this.panelTargets.filter(element => element.dataset.parameter === event.params.parameter)[0]
    close(panel)
  }

  toggleHidden(event) {
    const hiddenInput = document.getElementById(event.params.id)
    hiddenInput.checked = event.currentTarget.getElementsByTagName('input')[0].checked
  }
}