import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'toggleable'
  ];

  selectAll(event) {
    this.toggleableTargets.forEach((target) => {
      if (!target.disabled) {
        target.checked = true
      }
    })
    this.dispatch('changed', {detail: {id: event.params.id}})
    
  }

  selectNone(event) {
    this.toggleableTargets.forEach((target) => target.checked = false)
    this.dispatch('changed', {detail: {id: event.params.id}})
  }
}
