import { Controller } from '@hotwired/stimulus'
import { hideV2, showV2 } from '../utils/display'

export default class extends Controller {
    static targets = ['establishment', 'selectedContainer']

  toggleEstablishments({detail}) {
    const establishments = this.establishmentTargets.filter(({dataset}) => dataset.hidden == 'true')
    if (detail.value === 'Establishment' && establishments.length) {
      const ids = Array.from(establishments).map(({dataset}) => dataset.searchableSelectValueParam)
      Array.from(this.selectedContainerTarget.querySelectorAll('svg'))
           .filter(({dataset}) => ids.includes(dataset.searchableSelectValueParam))
           .forEach(tag => tag.parentNode.remove())
      hideV2(...establishments)
    } else if (establishments.length) {
      showV2(...establishments)
    }
  }
}
