import { Controller } from "@hotwired/stimulus"
import { open, close } from "../utils/display"

export default class extends Controller {
  static targets = ['panel', 'overlay']

  open() {
    open(this.panelTarget)
    open(this.overlayTarget)
  }

  close() {
    close(this.panelTarget)
    close(this.overlayTarget)
  }
}