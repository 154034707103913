import { Controller } from "@hotwired/stimulus"
import { showV2, hideV2, activate, desactivate, isActivated } from "../utils/display"

export default class extends Controller {
  static targets = [
    'establishmentSelect',
    'areasSection',
    'areas',
    'tabTemplate',
    'tabList',
    'tab',
    'tabPanel',
    'counter'
  ]

  onAreaTypeChange({detail: { value }}) {
    this.areaType = value
    switch (value) {
      case 'Company':
        hideV2(this.establishmentSelectTarget)
        hideV2(this.areasSectionTarget)
        this.uncheckAll()
        break;
      case 'Establishment':
        showV2(this.establishmentSelectTarget)
        hideV2(this.areasSectionTarget)
        this.uncheckAll()
        break;
      case 'Area':
        showV2(this.establishmentSelectTarget)
        const selectedOptions = this.establishmentSelectTarget.getElementsByTagName('select')[0].selectedOptions
        if (this.tabTargets.length) {
          showV2(this.areasSectionTarget)
        } else if (selectedOptions.length) {
          this.showAreaSection(selectedOptions)
        } else {
          hideV2(this.areasSectionTarget)
        }
        break;
      default:
        hideV2(this.establishmentSelectTarget)
        hideV2(this.areasSectionTarget)
        break;
    }
  }

  onEstablishmentSelect(event) {
    const { field, value} = event.detail

    if (this.areaType == 'Area' && field === 'establishment_ids') {
      showV2(this.areasSectionTarget)
      desactivate(this.tabTargets.map(tab => tab.children[0]))
      this.buildAreaSection(value)
    } else {
      hideV2(this.areasSectionTarget)
    }
  }

  showAreaSection(selectedOptions) {
    showV2(this.areasSectionTarget)
    const ids = []
    const values = []
    for (const option of selectedOptions) { ids.push(option.value) }
    const options = this.establishmentSelectTarget.getElementsByTagName('li')
    for (const option of options) {
      const { searchableSelectValueParam, label } = option.dataset
      if (ids.includes(searchableSelectValueParam))
        values.push({id:searchableSelectValueParam, name: label})
    }
    this.buildAreaSection(values)
  }

  buildAreaSection(values) {
    const ids = values.map(val => val.id)

    values.forEach(value => {
      this.buildTab({...value, active: value.id == ids[0]})
      this.updateCounter({detail: {id: value.id}})
    })
    const activePanel = this.tabPanelTargets.filter(panel => panel.dataset.id == ids[0])[0]
    hideV2(...this.tabPanelTargets)
    showV2(activePanel)

    const counters = this.counterTargets.filter(({dataset}) => ids.includes(dataset.id))
  }

  onEstablishmentRemove({detail: {value}}) {
    const panel = this.tabPanelTargets.filter(panel => panel.dataset.id == value)[0]
    const tab = this.tabTargets.filter(tab => tab.dataset.id == value)[0]

    if (tab) {
      const newCurrentTab = this.tabTargets.filter(tab => tab.dataset.id != value)[0]

      if (isActivated(tab.childNodes[0]) && newCurrentTab) {
        const { id } = newCurrentTab.dataset
        newCurrentTab.childNodes[0].classList.add('is-active')
        this.tabPanelTargets.filter(panel => panel.dataset.id == id)[0].classList.remove('is-hidden')
      }

      if (!newCurrentTab) { hideV2(this.areasSectionTarget) }
    }

    panel.classList.add('is-hidden')
    const checkboxes = panel.querySelectorAll('input[type="checkbox"]:checked')
    for (const checkbox of checkboxes) { checkbox.checked = false }

    if (tab) tab.remove()
  }

  changeTab(event) {
    hideV2(...this.tabPanelTargets)
    desactivate(this.tabTargets.map(tab => tab.children[0]))
    const { params: { tabId }, currentTarget } = event
    const activePanel = this.tabPanelTargets.filter(panel =>panel.dataset.id == tabId)[0]
    activate([currentTarget])
    showV2(activePanel)
  }

  buildTab({id, name, active}) {
    const tabTemplate = this.tabTemplateTarget.content.cloneNode(true).childNodes[0]
    tabTemplate.dataset.id = id
    const button = tabTemplate.childNodes[0]
    button.dataset.areaSelectionTabIdParam = id
    button.childNodes[0].innerHTML = name
    button.childNodes[1].dataset.id = id

    if (active) {
      button.classList.add('is-active')
    }

    this.tabListTarget.appendChild(tabTemplate)
  }

  initTabCounters(ids) {
    const counters = {}
    const countersToInit = this.counterTargets.filter(counter => ids.includes(counter.dataset.id))
    const availableTabPanel = this.tabPanelTargets.filter(panel => ids.includes(panel.dataset.id))

    availableTabPanel.forEach(panel => {
      counters[panel.dataset.id] = panel.querySelectorAll('input[type="checkbox"]:checked').length
    })

    countersToInit.forEach(counter => counter.innerHTML = counters[counter.dataset.id])
  }

  updateCounter(event) {
    const { id } = event.detail || event.currentTarget.dataset

    const panel = this.tabPanelTargets.filter(panel => panel.dataset.id == id)[0]
    const counter = this.counterTargets.filter(counter => counter.dataset.id == id)[0]
    counter.innerHTML = panel.querySelectorAll('input[type="checkbox"]:checked').length
  }

  uncheckAll() {
    this.element.querySelectorAll('input[type="checkbox"]:checked').forEach(element => element.checked = false)
    this.counterTargets.forEach(counter => counter.innerHTML = 0)
  }
}