import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // 5 minutes interval, as it is on sensors side
    this.intervalId = setInterval(() => window.history.go(), 300000);
  }

  disconnect() {
    clearInterval(this.intervalID);
  }
}
