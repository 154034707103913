import { Controller } from "@hotwired/stimulus"
import Popper from "popper.js";

export default class extends Controller {
  static targets = ["bubble"];

  connect() {
    if (!this.hasBubbleTarget) return;
    const bubble = this.bubbleTarget;
    const placement = this.element.dataset.placement;
    const withArrow = this.element.dataset.arrow === 'none' ? false : true

    const wrapper = document.createElement("div");
    wrapper.classList.add("a-tooltip__wrapper");
    bubble.parentNode.replaceChild(wrapper, bubble);
    wrapper.appendChild(bubble);

    if (withArrow) {
      const arrow = document.createElement("div");
      arrow.setAttribute("x-arrow", "");
      bubble.appendChild(arrow);
    }

    // Here to wait for the next drawing tick in order for PopperJS to be able to retrieve correct dimensions (If I understood correctly)
    setTimeout(() => {
      this.popper = new Popper(this.element, bubble, {
        placement: placement || "top"
      });
    }, 10);

    this.updateEventListener = this.element.addEventListener('mouseover', () => this.update());
  }

  disconnect() {
    clearInterval(this.openEventListener);
    clearInterval(this.closeAllEventListener);
    clearInterval(this.updateEventListener);
  }

  open() {
    this.update();
    this.element.classList.add("is-open");
  }

  close() {
    this.element.classList.remove("is-open");
  }

  closeAll(e) {
    if (!this.element.contains(e.target)) {
      this.close();
    }
  }

  update() {
    if (typeof (this.popper) != "undefined") {
      this.popper.update();
    }
  }
}
