import {
  addHours,
  subHours,
  startOfHour,
  endOfHour,
  addDays,
  subDays,
  startOfDay,
  endOfDay,
  addWeeks,
  subWeeks,
  startOfWeek,
  endOfWeek,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  getTime,
  max,
  min,
} from "date-fns";

export const DateRange = (config) => {
  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    stepSize,
    onDateChange,
  } = config;

  const hourNavigator = {
    previousStartDate: (date) => subHours(date, 1),
    nextStartDate: (date) => addHours(date, 1),
    startOfPeriod: startOfHour,
    endOfPeriod: endOfHour,
  };
  const dayNavigator = {
    previousStartDate: (date) => subDays(date, 1),
    nextStartDate: (date) => addDays(date, 1),
    startOfPeriod: startOfDay,
    endOfPeriod: endOfDay,
  };

  const weekNavigator = {
    name: "week",
    previousStartDate: (date) => subWeeks(date, 1),
    nextStartDate: (date) => addWeeks(date, 1),
    startOfPeriod: (date) => startOfWeek(date, { weekStartsOn: 1 }),
    endOfPeriod: (date) => endOfWeek(date, { weekStartsOn: 1 }),
  };

  const monthNavigator = {
    name: "month",
    previousStartDate: (date) => subMonths(date, 1),
    nextStartDate: (date) => addMonths(date, 1),
    startOfPeriod: startOfMonth,
    endOfPeriod: endOfMonth,
  };

  const quarterNavigator = {
    name: "quarter",
    previousStartDate: (date) => subMonths(date, 3),
    nextStartDate: (date) => addMonths(date, 3),
    startOfPeriod: startOfQuarter,
    endOfPeriod: endOfQuarter,
  };

  const getNavigator = (stepSize) => {
    switch (stepSize) {
      case "quarter":
        return quarterNavigator;
      case "month":
        return monthNavigator;
      case "week":
        return weekNavigator;
      case "day":
        return dayNavigator;
      case "hour":
        return hourNavigator;
    }
  };

  const minOr = (date) => {
    return minDate ? max([date, minDate]) : date;
  };

  const maxOr = (date) => {
    return maxDate ? min([date, maxDate]) : date;
  };

  const navigator = getNavigator(stepSize);

  const backward = () => {
    const newStartDate = minOr(navigator.previousStartDate(startDate));
    const newEndDate = maxOr(navigator.endOfPeriod(newStartDate));

    onDateChange(
      newStartDate,
      newEndDate,
      DateRange({
        ...config,
        startDate: newStartDate,
        endDate: newEndDate,
      })
    );
  };

  const forward = () => {
    const newStartDate = minOr(navigator.nextStartDate(startDate));
    const newEndDate = maxOr(navigator.endOfPeriod(newStartDate));

    onDateChange(
      newStartDate,
      newEndDate,
      DateRange({
        ...config,
        startDate: newStartDate,
        endDate: newEndDate,
      })
    );
  };

  const step = (newStepSize) => {
    const newStartDate = minOr(
      getNavigator(newStepSize).startOfPeriod(endDate)
    );
    const newEndDate = maxOr(
      getNavigator(newStepSize).endOfPeriod(newStartDate)
    );

    onDateChange(
      newStartDate,
      newEndDate,
      DateRange({
        ...config,
        startDate: newStartDate,
        endDate: newEndDate,
        stepSize: newStepSize,
      })
    );
  };

  const hasNext = () => {
    return (
      !maxDate ||
      getTime(navigator.nextStartDate(startDate)) <= getTime(maxDate)
    );
  };

  const hasPrevious = () => {
    return (
      !minDate ||
      getTime(navigator.previousStartDate(startDate)) >= getTime(minDate)
    );
  };

  const init = () => {
    onDateChange(startDate, endDate, DateRange(config), false);
  };

  return {
    init: init,
    backward: backward,
    forward: forward,
    hasPrevious: hasPrevious,
    hasNext: hasNext,
    step: step,
  };
};
