import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"
import {
  close,
  hideV2,
  showV2,
  activate,
  desactivate,
  toggleOpen
 } from "../utils/display"

export default class extends Controller {
  static targets = [
    'main_chartForm',
    'compared_chartForm',
    'startDate',
    'endDate',
    'parameter',
    'parameterLabel',
    'comparisonPanel',
    'areaItem',
    'areaType',
    'selectedArea',
    'clearAreaButton',
    'comparison'
  ]

  resetClickOutside() {
    if (this.hasComparisonTarget) {
      useClickOutside(this, {element: this.comparisonTarget})
    }
  }

  submitMain({ detail }) {
    this.action = 'datesChange'
    const { startDate, endDate } = detail
    this.startDateTargets.forEach(element => element.value = startDate)
    this.endDateTargets.forEach(element => element.value = endDate)

    if (this.hasMain_chartFormTarget) {
      this.main_chartFormTarget.requestSubmit()
    }
  }

  submitCompared() {
    if (this.hasCompared_chartFormTarget && this.action === 'datesChange') {
      this.compared_chartFormTarget.requestSubmit()
    } else if (this.hasCompared_chartFormTarget){
			document.getElementById('chart-compared_chart').dispatchEvent(new CustomEvent('ready'))
    }
  }

  changeParameter({ currentTarget }) {
    this.action = 'parameterChange'
    const { chartId } = currentTarget.dataset 

    this.parameterTargets
        .filter(({ dataset }) => dataset.chartId === chartId)
        .forEach(element => element.checked = false)
    desactivate(this.parameterLabelTargets.filter(({ dataset }) => dataset.chartId === chartId))

    currentTarget.checked = true
    this.parameterLabelTargets
        .filter(({ htmlFor }) => htmlFor == currentTarget.id)[0]
        .classList.add('is-active')

    const outdoorInput = document.getElementById('outdoor_pollutant_pollutant_code')
    if (chartId === 'main_chart' && outdoorInput) {
      outdoorInput.value = currentTarget.value
    }

    this.submitForm(chartId)
  }

  toggle() {
    toggleOpen(this.comparisonPanelTarget)
  }

  clickOutside() {
    if (this.hasComparisonPanelTarget) {
      close(this.comparisonPanelTarget)
    }
  }

  selectAreaType({ currentTarget, params }) {
    desactivate(this.areaTypeTargets)
    activate([currentTarget])
    
    hideV2(...this.areaItemTargets)
    showV2(...this.areaItemTargets.filter(element => element.dataset.areaType == params.areaType))
  }

  SelectAreaToCompare({ currentTarget }) {
    desactivate(this.areaItemTargets)
    activate([currentTarget])

    this.selectedAreaTarget.textContent = currentTarget.innerText.trim()
    this.selectedAreaTarget.classList.remove('placeholder')
    showV2(this.clearAreaButtonTarget)

    close(this.comparisonPanelTarget)
  }

  clearComparison(event) { 
    event.stopPropagation()
    document.getElementById('compared_chart').replaceChildren()
    const defautlAreaType = this.areaTypeTargets[0]
    desactivate([...this.areaTypeTargets, ...this.areaItemTargets])
    activate([defautlAreaType])
    hideV2(...this.areaItemTargets)
    showV2(...this.areaItemTargets.filter(element => element.dataset.areaType == defautlAreaType.dataset.pollutantHistoryAreaTypeParam))

    this.selectedAreaTarget.classList.add('placeholder')
    this.selectedAreaTarget.textContent = this.selectedAreaTarget.dataset.placeholder
    hideV2(this.clearAreaButtonTarget)
  }

  initComparedChart() {
    const startDate = this.startDateTargets.filter(element => !!element.value)[0].value
    const endDate = this.endDateTargets.filter(element => !!element.value)[0].value

    this.startDateTargets.forEach(element => {if (!element.value) { element.value = startDate }})
    this.endDateTargets.forEach(element => {if (!element.value) { element.value = endDate }})

    this.compared_chartFormTarget.requestSubmit()
  }

  submitForm(chartId) {
    if (chartId === 'main_chart') {
      this.main_chartFormTarget.requestSubmit()
    }

    if (chartId === 'compared_chart') {
      this.compared_chartFormTarget.requestSubmit()
    }
  }
}