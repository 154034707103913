import { Controller } from "@hotwired/stimulus"
import { open, close } from "../utils/display"

export default class extends Controller {
  static targets = [
    'panel',
  ]
  open() {
    open(this.panelTarget)
  }

  close() {
    close(this.panelTarget)
  }
}