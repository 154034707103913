import { Controller } from '@hotwired/stimulus'
import { useWindowResize } from 'stimulus-use'

export default class extends Controller {
  static targets = ['badgeList']

  connect() {
    document.addEventListener('DOMContentLoaded', () => {
      useWindowResize(this);
      this.badgeLists.forEach(badgeList => {
        this.initialize(badgeList);
      });

      // Écouter l'événement personnalisé "isSmallerChange" dans "application_controller.js"
      window.addEventListener('isSmallerChange', (event) => {
        const isSmaller = event.detail;
        this.badgeLists.forEach(badgeList => {
          this.updateDisplay(badgeList, window.innerWidth, isSmaller);
        });
      });

      // Vérifiez si l'élément "l-sidebar" a la classe "is-smaller" au chargement de la page
      if (this.hasSmallerClass()) {
        this.badgeLists.forEach(badgeList => {
          this.updateDisplay(badgeList, window.innerWidth, true);
        });
      }
    });
  }

  windowResize({ width }) {
    this.badgeLists.forEach(badgeList => {
      this.updateDisplay(badgeList, width, this.isSmaller);
    });
  }

  initialize(badgeList) {
    if (!badgeList) {
      // TODO: Correct more badges display later
      console.error('Couldn\'t initialise controller. `badgeList` element is not defined.');
      return;
    }

    const badgeSecondary = Array.from(badgeList.querySelectorAll('.badge__secondary'));
    const tag = badgeList.querySelector('.tag');

    if (!badgeSecondary || !tag) {
      console.error('Unable to find .badge__secondary or .tag elements.');
      return;
    }

    badgeList.dataset.initialDisplay = badgeList.style.display;

    if (tag) {
      badgeList.dataset.initialTagDisplay = tag.style.display;
    }

    this.updateDisplay(badgeList, window.innerWidth, this.isSmaller);
  }

  updateDisplay(badgeList, width, isSmaller) {
    const badgeSecondary = Array.from(badgeList.querySelectorAll('.badge__secondary'))
    const tag = badgeList.querySelector('.tag')
  
    if (width >= 1380 && isSmaller) {
      badgeList.style.display = badgeList.dataset.initialDisplay;
      if (badgeSecondary.length > 5) {
        badgeSecondary.slice(0, 5).forEach(element => {
          element.style.display = badgeList.dataset.initialDisplay;
        });
        badgeSecondary.slice(5).forEach(element => {
          element.style.display = 'none';
        });
      } else {
        badgeSecondary.forEach(element => {
          element.style.display = badgeList.dataset.initialDisplay;
        });
      }
      if (tag) {
        tag.style.display = 'none';
      }
    } else {
      badgeSecondary.forEach((element) => {
        element.style.display = 'none';
      });
      badgeList.style.display = badgeList.dataset.initialDisplay;
      badgeSecondary.slice(0, 4).forEach(element => {
        element.style.display = badgeList.dataset.initialDisplay;
      });
      if (tag) {
        tag.style.display = "flex";
      }
    }
  }  

  get badgeLists() {
    return this.badgeListTargets
  }

  hasSmallerClass() {
    const sidebar = document.querySelector('.l-sidebar');
    return sidebar && sidebar.classList.contains('is-smaller');
  }
}
