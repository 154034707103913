import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["action", "checkbox", "selectAll", "table"]

  initialize() {
    this.actionTargets.forEach((action) => {
      const oldAction = action.getAttribute('data-action') || "";
      const newAction = `click->turbo-bulk-actions#click ${oldAction}`;
      action.setAttribute('data-action', newAction.trim())
    })
  }

  selectAll(event) {
    if (event.target.checked) {
      this.checkboxTargets.forEach((checkbox) => {
        if (!checkbox.disabled) {
          checkbox.checked = true
        }
      });
    } else {
      this.checkboxTargets.forEach((checkbox) => checkbox.checked = false)
    }
  }

  select(event) {
    if (!event.target.checked && this.selectAllTarget.checked) {
      this.selectAllTarget.checked = false
    }
  }

  click(event) {
    event.preventDefault()
    if (!this.checkboxTargets.filter((checkbox) => checkbox.checked).length) return

    const data = {ids: this.checkboxTargets.filter((checkbox) => checkbox.checked).map((item) => item.value), page: this.tableTarget.dataset.page}
    this.url = `${event.currentTarget.dataset.url}?${new URLSearchParams(data)}`

    fetch(this.url, {
      method: 'GET',
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      },
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}