import { Controller } from '@hotwired/stimulus'
import { hideV2, showV2 } from "../utils/display"

const PDF_TYPE =  'application/pdf'
const XLS_TYPE = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]
const DOC_TYPE = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

const CSV_TYPE = 'text/csv'

const file_url = (file, urls) => {
  const { type } = file

  if (XLS_TYPE.includes(type)) { return urls.xlsUrl }

  if (DOC_TYPE.includes(type)) { return urls.docUrl }

  if (type === PDF_TYPE) { return urls.pdfUrl }
  if (type === CSV_TYPE) { return urls.csvUrl }

  return URL.createObjectURL(file)
}

export default class extends Controller {
  static targets = ['input', 'pdfImage', 'input', 'buttons', 'image']

  initialize() {
    this.setupDragAndDropListeners()
  }

  setupDragAndDropListeners() {
    this.element.addEventListener('dragover', this.handleDragOver.bind(this))
    this.element.addEventListener('dragleave', this.handleDragLeave.bind(this))
    this.element.addEventListener('drop', this.handleDrop.bind(this))
  }

  handleDragOver(event) {
    event.preventDefault()
    this.element.classList.add('dropzone-area__dragover')
  }

  handleDragLeave() {
    this.element.classList.remove('dropzone-area__dragover')
  }

  handleDrop(event) {
    this.element.classList.remove('dropzone-area__dragover')
    const [file] = event.dataTransfer.files
    this.updateImageDisplay(file)
  }

  preview({currentTarget}) {
    const [file] = currentTarget.files
    this.updateImageDisplay(file)
    if (this.hasButtonsTarget) { showV2(this.buttonsTarget) }
  }

  updateImageDisplay(file) {
    if (!file) return
    if (this.hasImageTarget) { this.imageTarget.remove() }

    const { style, dataset } = this.element
    const url = file_url(file, dataset)

    style.backgroundImage = `url('${url}')`
    style.backgroundSize = 'contain'
    style.backgroundRepeat = 'no-repeat'
    style.backgroundPosition = 'center'

    Array.from(this.element.children)
         .forEach(child => child.classList.add('is-hidden', child.tagName != 'INPUT'))

    this.dispatch('change', { detail: { filename: file.name } })
  }

  swapPhoto() {
    this.inputTarget.click()
  }

  resetDropzone() {
    if (this.element.style.backgroundImage === '') return

    this.element.style = ''
    Array.from(this.element.children)
         .forEach(child => child.classList.remove('is-hidden', child.tagName != 'INPUT'))
    this.element.querySelector('#co2_import').value = ''
  }
}
