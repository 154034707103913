import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { requestChart } from "../charts/utils";
import { AsyncBox } from "../charts/async_box";
import { show, hide } from "../utils/display";
import {
    endOfDay,
    endOfMinute,
    parseISO,
    formatISO,
    startOfMinute,
    addMinutes,
    getTime,
    startOfDay,
    addDays,
    subDays,
    subHours,
    subMilliseconds,
    addMilliseconds,
    differenceInMilliseconds,
    isAfter,
} from "date-fns";

export default class extends Controller {

    static targets = [
        "graph",
        "box",
        "loader",
        "form",
        "startDate",
        "endDate",
        "dateRange",
        "buttonNext",
        "buttonPrevious",
        "buttonTimespan",
        "room",
        "comparisonBox",
        "comparisonLoader",
        "template",
    ];

    initialize() {
        const endDate = endOfDay(new Date());
        const startDate = startOfDay(subDays(endDate, 2));

        this.defaultDates = [startDate, endDate];
        this.graphBox = AsyncBox(
            "pollutant history chart",
            this.loaderTarget,
            this.boxTarget
        );

        if (this.hasComparisonLoaderTarget && this.hasComparisonBoxTarget) {
            this.graphComparisonBox = AsyncBox(
                "comparison pollutant history chart",
                this.comparisonLoaderTarget,
                this.comparisonBoxTarget
            );
        }
    }

    connect() {
        this.dateRangePicker = this.initDateRangePicker();

        this.minuteStep = false;

        this.synchronizeDateChange(...this.defaultDates);
    }

    loadLastPeriodGraph(event) {
        const now = new Date();

        this.buttonTimespanTargets.map(button => button.classList.remove('is-selected'))
        event.currentTarget.classList.add('is-selected')

        event.preventDefault();
        this.minuteStep = true;

        const periodDuration = parseInt(
            event.currentTarget.dataset.lastPeriodDuration
        );

        const endDate = isAfter(this.dateRangePicker.selectedDates[1], now)
            ? endOfMinute(now)
            : endOfMinute(this.dateRangePicker.selectedDates[1]);

        const startDate = addMinutes(startOfMinute(subHours(endDate, periodDuration)), 1);

        this.synchronizeDateChange(startDate, endDate);
    }

    previousDateRange() {
        const oldStartDate = this.dateRangePicker.selectedDates[0];
        const oldEndDate = this.dateRangePicker.selectedDates[1];
        const timeRange = differenceInMilliseconds(oldEndDate, oldStartDate);

        const newEndDate =
            this.minuteStep == false
                ? endOfDay(subDays(oldStartDate, 1))
                : endOfMinute(oldStartDate);

        const newStartDate = subMilliseconds(getTime(newEndDate), timeRange);

        this.synchronizeDateChange(newStartDate, newEndDate);
    }

    nextDateRange() {
        const oldStartDate = this.dateRangePicker.selectedDates[0];
        const oldEndDate = this.dateRangePicker.selectedDates[1];
        const timeRange = differenceInMilliseconds(oldEndDate, oldStartDate);

        const newStartDate =
            this.minuteStep == false
                ? startOfDay(addDays(oldEndDate, 1))
                : startOfMinute(oldEndDate);

        const newEndDate = addMilliseconds(getTime(newStartDate), timeRange);

        this.synchronizeDateChange(newStartDate, newEndDate);
    }

    initDateRangePicker() {
        return flatpickr(this.dateRangeTarget, {
            mode: "range",
            locale: this.data.get('locale'),
            dateFormat: "d/m/Y H:i",
            maxDate: endOfDay(new Date()),
            onChange: (selectedDates) => {
                if (selectedDates.length == 2) {
                    this.minuteStep = false;

                    const startDate = startOfDay(selectedDates[0]);
                    const endDate = endOfDay(selectedDates[1]);

                    this.buttonTimespanTargets.map(button => button.classList.remove('is-selected'))

                    this.synchronizeDateChange(startDate, endDate);
                }
            },
        });
    }

    synchronizeDateChange(startDate, endDate) {
        const today = endOfDay(new Date());

        if (isAfter(endDate, today)) endDate = today;
        this.dateRangePicker.setDate([startDate, endDate]);

        this.startDateTarget.value = formatISO(startDate);
        this.endDateTarget.value = formatISO(endDate);

        this.loadGraph();
        if (this.hasComparisonBoxTarget) {
            this.loadComparisonGraph();
        }
    }

    loadGraph() {
        this.graphBox.loading();
        requestChart(
            this.formTarget,
            (response) => {
                this.graphBox.load(response["graph"]);

                if (isAfter(parseISO(this.endDateTarget.value), new Date())) {
                    this.buttonNextTarget.setAttribute("disabled", true);
                } else {
                    this.buttonNextTarget.removeAttribute("disabled");
                }
            },
            (...args) => {
                this.graphBox.error(...args, new FormData(this.formTarget));
            }
        );
    }

    loadComparisonGraph() {
        if (this.hasRoomTarget && this.roomTarget.value) {
            show(this.templateTarget);

            this.graphComparisonBox.loading();

            this.formTarget.elements[
                "pollutant_history[sensor_group_id]"
                ].value = this.roomTarget.value;
            requestChart(
                this.formTarget,
                (response) => {
                    this.graphComparisonBox.load(response["graph"]);
                },
                (...args) => {
                    this.graphComparisonBox.error(...args, new FormData(this.formTarget));
                }
            );
            this.formTarget.elements[
                "pollutant_history[sensor_group_id]"
                ].value = this.data.get("room-id");
        } else {
            hide(this.templateTarget);
        }
    }
}
