import { Controller } from "@hotwired/stimulus"
import { activate, desactivate } from "../utils/display"

const DESACTIVATE_STATE = 'desactived'
const ACTIVATE_STATE = 'actived'

export default class extends Controller {
  static targets = ['table', 'row', 'improvingBtn', 'worseningBtn']

  worsening() {
    if (this.worseningBtnTarget.dataset.state === ACTIVATE_STATE) {
      this.byName(this.worseningBtnTarget)
      return
    }

    let rows = this.rowTargets.sort((a, b) => a.dataset.worseningRank - b.dataset.worseningRank)
    rows = this.updateRowRank(rows)
    this.updateTable(rows)

    this.activateWorsening()
  }

  improving() {
    if (this.improvingBtnTarget.dataset.state === ACTIVATE_STATE) {
      this.byName(this.improvingBtnTarget)
      return
    }

    let rows = this.rowTargets.sort((a, b) => a.dataset.improvingRank - b.dataset.improvingRank)
    rows = this.updateRowRank(rows)
    this.updateTable(rows)

    this.activateImpoving()
  }

  byName(target) {
    let rows = this.rowTargets.sort((a, b) => {
      if (a.dataset.name < b.dataset.name) { return -1 }
      if (a.dataset.name > b.dataset.name) { return 1 }
      return 0
    })

    rows = this.updateRowRank(rows)
    target.dataset.state = DESACTIVATE_STATE
    desactivate([target])
    this.updateTable(rows)
  }

  updateTable(rows) {
    this.tableTarget.innerHTML = ''
    rows.forEach(row => this.tableTarget.appendChild(row))
  }

  updateRowRank(rows) {
    rows.forEach((row, index) => row.children[0].textContent = index + 1)
    return rows
  }

  toggleState(target) {
    target.dataset.state = target.dataset.state === ACTIVATE_STATE ?  DESACTIVATE_STATE : ACTIVATE_STATE
  }

  activateImpoving() {
    this.improvingBtnTarget.dataset.state = ACTIVATE_STATE
    this.worseningBtnTarget.dataset.state = DESACTIVATE_STATE
    activate([this.improvingBtnTarget])
    desactivate([this.worseningBtnTarget])
  }

  activateWorsening() {
    this.worseningBtnTarget.dataset.state = ACTIVATE_STATE
    this.improvingBtnTarget.dataset.state = DESACTIVATE_STATE
    activate([this.worseningBtnTarget])
    desactivate([this.improvingBtnTarget])
  }
}
