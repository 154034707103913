import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static targets = ["chart"];

  initialize() {
    document.addEventListener("DOMContentLoaded", () => {
      const elem = this.chartTarget;
      this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
      this.chart.render();
    });
  }

  get chartOptions() {
    return {
      chart: {
        type: 'area',
        height: '75%',
        foreColor: "#999",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      series: [{
        name: 'Good',
        data: [44, 55, 41, 37, 22, 43, 21]
      }],
      colors: ["#6275DC"],
      xaxis: {
        categories: ["00:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00"],
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        borderColor: '#d3dae6',
        strokeDashArray: 2,
        position: 'back',
        xaxis: {
          lines: {show: false}
        },
        yaxis: {
          lines: {show: true}
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.70,
          opacityTo: 0.30,
          stops: [10, 100, 100, 100]
        }
      },
      legend: {
        show: false
      }
    };
  }
}