import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['headerHeight', 'subheaderHeight', 'tabContainerHeight', 'rulesContainerHeight', 'summaryContainerHeight'];

  connect() {
    this.calculateHeight();
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        this.calculateHeight();
      }
    });
  }

  calculateHeight() {
    if (window.innerWidth >= 1024) {
      const headerHeight = this.headerHeightTarget.offsetHeight || 0;
      const subheaderHeight = this.subheaderHeightTarget.offsetHeight || 0;
      const tabContainerHeight = this.tabContainerHeightTarget.offsetHeight || 0;

      const calculatedHeight = `calc(100vh - ${tabContainerHeight}px - ${subheaderHeight}px - ${headerHeight}px)`;

      this.rulesContainerHeightTarget.style.height = calculatedHeight;
      if (this.hasSummaryContainerHeightTarget) {
        this.summaryContainerHeightTarget.style.height = `calc(${calculatedHeight} - 80px)`;
      }
    } else {
      this.rulesContainerHeightTarget.style.height = 'auto';
      if (this.hasSummaryContainerHeightTarget) {
        this.summaryContainerHeightTarget.style.height = 'auto';
      }
    }
  }
}