import { Controller } from "@hotwired/stimulus"
import { hideV2, showV2 } from "../utils/display"

export default class extends Controller {
	static targets = [
		'toggle',
		'main_chartForm',
		'form',
		'source',
		'chart',
		'chartId',
		'pollutant'
	]

	initialize() {
		this.checked = false
		this.serieName = this.data.get('serieName')
		this.codes = JSON.parse(this.data.get('codes'));
		this.outdoorZoneCodes = JSON.parse(this.data.get('ozCodes'));
	}

	connect() {
		const code = this.main_chartFormTarget.elements['main_chart_pollutant_history[pollutant_code]'].value
		this.formTarget.elements['outdoor_pollutant[pollutant_code]'].value = code
	}

	removeHistory() {
		const removeEvent = new CustomEvent('remove-history', {detail: {serieName: this.serieName}})
		const outdoorMainChartPresent = !!this.chartTargets.filter(({dataset}) => dataset.target === 'main_chart').length
		const outdoorComparedChartPresent = !!this.chartTargets.filter(({dataset}) => dataset.target === 'compared_chart').length
		const comparedChart = document.getElementById('chart-compared_chart')

		if (outdoorMainChartPresent) {
			document.getElementById('chart-main_chart').dispatchEvent(removeEvent)
			this.hideSource('outdoor-source-main_chart')
		}

		if (comparedChart && outdoorComparedChartPresent) {
			comparedChart.dispatchEvent(removeEvent)
			this.hideSource('outdoor-source-compared_chart')
		}
	}

	hideSource(sourceTarget) {
		const outdoorSource = document.getElementById(sourceTarget)
		if (outdoorSource) hideV2(outdoorSource)
	}

	displaySource(sourceTarget) {
		const outdoorSource = document.getElementById(sourceTarget)
		if (outdoorSource) showV2(outdoorSource)
	}

	displayHistory(chartId) {
		const displayEvent = new CustomEvent('display-history', {detail: {serieName: this.serieName}})
		document.getElementById(chartId).dispatchEvent(displayEvent)
		this.displaySource('outdoor-source-main_chart')
		this.displaySource('outdoor-source-compared_chart')
	}

	submitForm({detail}) {
		const { chartId, code } = detail
		this.chartIdTarget.value = chartId.split('-')[1]
		this.pollutantTarget.value = code

		if (this.outdoorZoneCodes.includes(code)) {
			this.sourceTarget.value = 'outdoor_zone'
		} else {
			this.sourceTarget.value = 'open_data'
		}

		const outdoorChartPresent = !!this.chartTargets.filter(({dataset}) => dataset.target === chartId.split('-')[1]).length
		if (this.checked && this.codes.includes(code) && !outdoorChartPresent) {
			this.formTarget.requestSubmit()
		}else if (this.checked && outdoorChartPresent) {
			this.displayHistory(chartId)
			this.mainChartReadyEvent(chartId)
		}

		if (!this.checked || !this.codes.includes(code)) {
				this.mainChartReadyEvent(chartId)
		}

	}

	toggle({ currentTarget}) {
		if (currentTarget.checked) {
			this.checked = true
			const mainChartPollutant = this.main_chartFormTarget.elements['main_chart_pollutant_history[pollutant_code]'].value
			this.submitForm({detail: {chartId:'chart-main_chart', code: mainChartPollutant}})
		} else {
			this.checked = false
			this.removeHistory()
		}
	}

	mainChartReadyEvent(chartId) {
		if (chartId === 'chart-main_chart') {
			document.getElementById('chart-main_chart').dispatchEvent(new CustomEvent('ready'))
		}
	}
}
