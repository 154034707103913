import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import { open, close, isOpened } from "../utils/display";

export default class extends Controller {
  static targets = ["panel", "display", "menuLink", "listItem", "hiddenField"];
  static values = {
    statuses: Array
  };

  connect() {
    useClickOutside(this);
  }

  toggle() {
    isOpened(this.panelTarget) ? close(this.panelTarget) : open(this.panelTarget);
  }

  clickOutside() {
    if (this.hasPanelTarget) {
      close(this.panelTarget);
    }
  }

  select(event) {
    const selectedValue = event.currentTarget.value;
    const selectedText = event.currentTarget.textContent.trim();

    this.updateDisplay(selectedText, selectedValue);
    this.updateHiddenField(selectedValue);
  }

  updateDisplay(text, value) {
    this.displayTarget.querySelector('.tag_text').textContent = text;

    this.statusesValue.forEach(status => {
      this.displayTarget.classList.remove(status);
    });

    this.displayTarget.classList.add(value);
  }

  updateHiddenField(value) {
    this.hiddenFieldTarget.value = value;
  }

}
