import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["card"];

  connect() {
    if (this.cardTargets.length % 2) {
      this.cardTargets.slice(-1)[0].classList.add('o-last-card')
    }
  }

}
