import { Controller } from "@hotwired/stimulus";
import { activate, desactivate, hideV2, isActivated, showV2 } from "../utils/display";

export default class extends Controller {
  static targets = [
    'form',
    'startDate',
    'endDate',
    'trigger',
    'tabField',
    'tab',
    'filter',
    'skeleton',
    'chart'
  ]

  submitAll({ detail }) {
    const { startDate, endDate } = detail

    this.triggerTarget.value = ''
    this.startDateTargets.forEach(element => element.value = startDate)
    this.endDateTargets.forEach(element => element.value = endDate)

    if (this.hasFormTarget) {
      this.formTarget.requestSubmit()
    }
  }

  submit({ currentTarget, params }) {
    if (isActivated(currentTarget)) { return }

    const { dataset: { type }} = currentTarget
    const { tab } = params

    this.triggerTarget.value = type
    this.tabFieldTargets.filter(element => element.dataset.type === type)[0].value = tab
    desactivate(this.tabTargets.filter(element => element.dataset.type === type))
    activate([currentTarget])

    if (this.hasFormTarget) {
      this.formTarget.requestSubmit()
    }
  }

  selectFilter({ currentTarget }) {
    const { dataset: { chart }} = currentTarget
    desactivate(this.filterTargets.filter(element => element.dataset.chart === chart))
    activate([currentTarget])
  }

  hideSkeleton({ detail }) {
    this.triggerTarget.value = 'chart'
    const trigger = detail.formSubmission.formData.get("dashboard_history[trigger]")
    if (trigger) {
      hideV2(...this.skeletonTargets.filter(element => element.dataset.chart == trigger))
      showV2(...this.chartTargets.filter(element => element.dataset.chart == trigger))
    } else {
      hideV2(...this.skeletonTargets)
      showV2(...this.chartTargets)
    }
  }

  hideChart({ detail }) {
    const trigger = detail.formSubmission.formData.get("dashboard_history[trigger]")
    if (trigger) {
      hideV2(...this.chartTargets.filter(element => element.dataset.chart == trigger))
      showV2(...this.skeletonTargets.filter(element => element.dataset.chart == trigger))
    } else {
      hideV2(...this.chartTargets)
      showV2(...this.skeletonTargets)
    }
  }
}