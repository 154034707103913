import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  next(event) {
    if (event.detail.success) {
      document.getElementById('sidepanel').parentElement.classList.remove('is-open')
      const { response } = event.detail.fetchResponse
      window.location.replace(response.url)
    }
  }
}

