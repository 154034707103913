import { Controller } from '@hotwired/stimulus'
import { showV2, hideV2, desactivate, activate } from '../utils/display'

export default class extends Controller {
  
  static targets = [
    'step',
    'sectionButton',
    'section',
    'navStep',
    'parentNavStep',
    'navSection',
    'section2Part1',
    'section2Part2',
    'loader',
    'successDialog'
  ]

  connect() {
    if (this.hasLoaderTarget) {
      setTimeout(() => {
        hideV2(this.loaderTarget)
        showV2(this.successDialogTarget)
      }, 2000);
    }

    if (this.hasSectionButtonTarget) {
      this.showStep(0)
      activate([this.sectionButtonTarget, this.navStepTarget])
      this.hideOtherSections(0)
    }
  }

  switchTab({currentTarget}) {
    const { step, subIndex } = currentTarget.dataset
    this.resetNav()

    if (subIndex) {
      this.navStepTargets
        .filter(({dataset}) => dataset.subIndex === subIndex)
        .forEach(navStep => navStep.classList.toggle('is-active', navStep.dataset.step === step))
    } else {
      this.navStepTargets
        .forEach(navStep => navStep.classList.toggle('is-active', navStep.dataset.step === step))
    }

    this.stepTargets.forEach(element => element.classList.toggle('is-hidden', element.id !== step))
    this.activeParent()
  }

  activeParent() {
    const currentStep = this.navStepTargets.filter(step => step.classList.contains('is-active'))[0]
    const parent = currentStep.parentElement

    if (parent.classList.toString().includes('secondary')) {
      parent.previousSibling.classList.add('is-active') 
    }
  }

  resetNav() {
    desactivate(this.navStepTargets)
    if (this.hasParentNavStepTarget) {
      desactivate(this.parentNavStepTargets)
    }
  }

  showStep(index) {
    hideV2(...this.stepTargets)
    showV2(this.stepTargets[index])
  }

  hideOtherSections(index) {
    hideV2(...this.sectionTargets)
    hideV2(...this.navSectionTargets)

    this.sectionTargets.forEach(section => {
      const sectionIndex = parseInt(section.dataset.index)

      sectionIndex === index ? showV2(section) : hideV2(section)
    })

    this.navSectionTargets.forEach(navSection => {
      const navSectionIndex = parseInt(navSection.dataset.index)

      navSectionIndex === index ? showV2(navSection) : hideV2(navSection)
    })
  }

  selectSection({currentTarget}) {
    const { index } = currentTarget.dataset;
    const section = this.sectionTargets.find(({dataset}) => dataset.index == index)
    this.resetNav()
    this.changeSection({section})
    this.currentSectionIndex = parseInt(index);
  }

  nextStep({currentTarget}) {
    this.navStepTargets.forEach(navStep => {
      navStep.classList.remove("is-active")
    })

    const currentStep = currentTarget.closest('[data-annual-form-target="step"]')
    const section = currentStep.closest('[data-annual-form-target="section"]')
    const currentSectionIndex = parseInt(section.dataset.index)
    const currentSectionSteps = Array.from(section.querySelectorAll('[data-annual-form-target="step"]'))
    const currentStepIndex = currentSectionSteps.indexOf(currentStep)
    const nextIndex = currentStepIndex + 1
    const stepsInSection = currentSectionSteps.length

    if (nextIndex < stepsInSection) {
      const nextStep = currentSectionSteps[nextIndex]
      showV2(nextStep)
      hideV2(currentStep)

      const nextStepNav = this.navStepTargets.find(navStep => navStep.dataset.step === nextStep.id)
      if (nextStepNav) {
        nextStepNav.classList.add("is-active")
      }
    } else {
      const nextSectionIndex = currentSectionIndex + 1
      const nextSection = this.sectionTargets.find(section => section.dataset.index == nextSectionIndex)

      if (nextSection) {
        this.changeSection({section: nextSection})
      }
    }

    this.changeSummarySection2()
  }

  changeSection({section, showFirstStep = true}) {
    if (showFirstStep) {
      hideV2(...this.stepTargets)
      showV2(section.querySelectorAll('[data-annual-form-target="step"]')[0])
    }

    desactivate(this.sectionButtonTargets)
    const currentSectionButton = this.sectionButtonTargets.find(({dataset}) => dataset.index == section.dataset.index)
    activate([currentSectionButton])
    hideV2(...this.sectionTargets)
    showV2(section)

    hideV2(...this.navSectionTargets)
    const currentNavSection = this.navSectionTargets.find(({dataset}) => dataset.index == section.dataset.index)
    const firstNavStepInSection = currentNavSection.querySelector('[data-annual-form-target="navStep"]')
    firstNavStepInSection.classList.add("is-active")
    this.activeParent()
    showV2(currentNavSection)

    //Only useful for autodiagnostic
    if (this.hasSection2Part1Target && this.hasSection2Part2Target) {
      hideV2(this.section2Part2Target)
      showV2(this.section2Part1Target)
    }
  }

  previousStep() {
    const currentStep = this.stepTargets.find(step => !step.classList.contains("is-hidden"))
    const currentSection = currentStep.closest('[data-annual-form-target="section"]')
    const previousIndex = this.stepTargets.indexOf(currentStep) - 1
    const previousStep = this.stepTargets[previousIndex]
    const previousSection = previousStep.closest('[data-annual-form-target="section"]')

    this.showStep(previousIndex)
    if (currentSection != previousSection) {
      this.changeSection({section: previousSection, showFirstStep: false})
    }

    desactivate(this.navStepTargets)

    const previousStepNav = this.navStepTargets.find(navStep => navStep.dataset.step === previousStep.id);
    previousStepNav.classList.add("is-active");

    this.changeSummarySection2()
  }

  changeSummarySection2() {
    if (this.hasSection2Part1Target && this.hasSection2Part2Target) {
      const activeElement = this.element.querySelector('.building-form__section-button.is-active[data-action="click->annual-form#switchTab"]');

      if (activeElement) {
        const step = activeElement.dataset.step;

        if (step === 'section2-step1' || step === 'section2-step2') {
          showV2(this.section2Part1Target)
          hideV2(this.section2Part2Target)
        } else if (step === 'section2-step3' || step === 'section2-step7') {
          hideV2(this.section2Part1Target)
          showV2(this.section2Part2Target)
        }
      }
    }
  }

  section2Part2ButtonClick(event) {
    event.preventDefault();

    if (this.hasSection2Part1Target && this.hasSection2Part2Target) {
      showV2(this.section2Part1Target)
      hideV2(this.section2Part2Target)

      const activeButton = this.element.querySelector('[data-step="section2-step1"]');
      activeButton.classList.add('is-active');

      activeButton.click();
    }
  }

  section2Part1ButtonClick(event) {
    event.preventDefault();

    if (this.hasSection2Part1Target && this.hasSection2Part2Target) {
      hideV2(this.section2Part1Target)
      showV2(this.section2Part2Target)

      const activeButton = this.element.querySelector('[data-step="section2-step3"]');
      activeButton.classList.add('is-active');

      activeButton.click();
    }
  }
}