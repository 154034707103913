import { Controller } from "@hotwired/stimulus"
import noUiSlider from 'nouislider';

export default class extends Controller {
  static targets = ['input'];

  initialize() {
    const values = this.inputTargets.map((input) => parseInt(input.value));
    const { step, min, max } = this.element.dataset

    this.slider = noUiSlider.create(this.element, {
      start: values,
      connect: true,
      step: parseInt(step),
      range: { min: parseInt(min), max: parseInt(max) },
      tooltips: true,
      format: {
        to: value => `${Math.ceil(value)}h`,
        from: value => Math.ceil(value)
      }
    })

    this.slider.on('change', (values) => {
      values.map((value, index) => {
        this.inputTargets[index].value = Math.ceil(parseInt(value));
      });
    });
  }
}
