import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils/display";

export default class extends Controller {

  static targets = ["select", "establishment", "zone", "floor", "floorInput"];

  connect() {
    this.fetchZonesList(this.establishmentTarget.value);
    this.fetchFloorList(this.establishmentTarget.value);
  }

  displayZones(event) {
    if (this.hasEstablishmentTarget) {
      const establishmentId = event.currentTarget.value;
      this.fetchZonesList(establishmentId);
    }
  }

  displayFloors(event) {
    if (this.hasFloorTarget && this.hasFloorInputTarget) {
      const establishmentId = event.currentTarget.value;
      this.fetchFloorList(establishmentId);
    }
  }

  fetchFloorList(establishmentId) {
    let floorTargetsToShow = [];
    let floorTargetsToShowHasZero = false;
    const defaultFloor = this.floorInputTarget.dataset.defaultFloor
    this.floorInputTarget.value = null;

    this.floorTargets.forEach((target) => {
      if (establishmentId === target.dataset.establishmentId) {
        show(target);
        floorTargetsToShow.push(target);

        if (target.value == defaultFloor) {
          target.selected = true;
        }
        if (target.value == 0) {
          floorTargetsToShowHasZero = true;
        }
      } else {
        target.selected = false;
        hide(target);
      }
    });


    if (!this.floorInputTarget.value) {
      this.setDefaultFloorValue(floorTargetsToShow, floorTargetsToShowHasZero)
    }
  }

  setDefaultFloorValue(floors, hasZero) {
    this.floorInputTarget.value = hasZero ? 0 : floors[0].value
  }

  fetchZonesList(establishmentId) {
    this.hasZone = false;
    this.zoneTargets.forEach((target) => {
      if (establishmentId === target.dataset.establishmentId) {
        show(target);
        this.hasZone = true;
        if (target.value === this.selectTarget.dataset.assocId) {
          target.selected = true;
        }
      } else {
        target.selected = false;
        hide(target);
      }
    });

    if (this.hasZone) {
      show(this.selectTargets);
    } else {
      hide(this.selectTargets);
    }
  }
}
