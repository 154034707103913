import { Controller } from '@hotwired/stimulus'

const EDIT_DEFAULT_CONF = {
  menubar: false,
  toolbar: ["undo redo | blocks | bold italic underline | bullist numlist outdent indent | link"],
  plugins: "insertdatetime lists media table code link",
  height: 320
}
const READ_DEFAULT_CONF = {
  ...EDIT_DEFAULT_CONF,
  readonly: true
}

export default class extends Controller {
    static targets = ['input']

    connect() {
      let default_config = this.inputTarget.dataset.readonly == "true" ? READ_DEFAULT_CONF : EDIT_DEFAULT_CONF
      default_config.selector = `#${this.inputTarget.id}`
      let config = Object.assign({ target: this.inputTarget }, default_config )
      tinymce.init(config)
    }

    disconnect () {
        if (!this.preview) tinymce.remove()
    }

    get preview () {
        return (
            document.documentElement.hasAttribute('data-turbolinks-preview') ||
            document.documentElement.hasAttribute('data-turbo-preview')
        )
    }
}