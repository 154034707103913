import { Controller } from "@hotwired/stimulus"
import { open, close } from "../utils/display"

export default class extends Controller {
  static targets = ['confirmModal', 'confirmOverlay', 'sensorsModal', 'sensorsOverlay']

  showConfirmModal() {
    open(this.confirmModalTarget)
    open(this.confirmOverlayTarget)
  }

  hideConfirmModal() {
    close(this.confirmModalTarget)
    close(this.confirmOverlayTarget)
  }

  showSensors() {
    open(this.sensorsModalTarget)
    open(this.sensorsOverlayTarget)
  }

  closeSensors() {
    close(this.sensorsModalTarget)
    close(this.sensorsOverlayTarget)
  }
}