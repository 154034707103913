import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static targets = ["chart"];

  initialize() {
    this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
    this.chart.render();
  }

  get chartOptions() {
    return {
      chart: {
        type: 'bar',
        height: '224px',
      },
      series: [{
        name: 'Good',
        data: [1, 1, 6]
      }, {
        name: 'Intermediate',
        data: [1, 4, 2]
      }, {
        name: 'Bad',
        data: [3, 1, 0]
      }],
      colors: ["#00D61F", "#FFD900", "#FF3E56"],
      xaxis: {
        categories: ['S39', 'S40', 'S41'],
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false
      },
    }
  }
}
