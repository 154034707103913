import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use"
import { open, close, isOpened } from "../utils/display"

export default class extends Controller {
  static targets = ["text", "panel", "categoryItem", "item", "rightSideIcon", "compareButton"]

  connect() {
    useClickOutside(this)

    this.selectDefaultCategory();
  }

  selectDefaultCategory() {
    this.selectCategory(1);
  }

  selectCategory(categoryIndex) {
    this.categoryItemTargets.forEach(categoryItem => {
      categoryItem.classList.remove('is-active');
    })
    this.categoryItemTargets[categoryIndex].classList.add('is-active');

    this.itemTargets.forEach((item) => {
      item.style.display = "block";
    })
    
    const inactiveItems = this.itemTargets.filter(item => {
      return item.dataset.categoryIndex != categoryIndex;
    })

    inactiveItems.forEach(item => {
      item.style.display = 'none';
    })
  }

  toggle() {
    isOpened(this.panelTarget) ? close(this.panelTarget) : open(this.panelTarget)
  }

  clickOutside() {
    if (this.hasPanelTarget) {
      close(this.panelTarget)
    }
  }

  handleClickCategory(event) {
    const selectedIndex = event.currentTarget.dataset.categoryIndex
    
    this.selectCategory(selectedIndex);
  }

  clearSelectedItem() {
    this.itemTargets.forEach(item => {
      item.classList.remove('is-active');
    })
  }

  handleClickItem(event) {
    this.clearSelectedItem();

    event.currentTarget.classList.add('is-active');
    const selectedItemText = event.currentTarget.innerText.trim();
    this.textTarget.textContent = selectedItemText;

    const iconContent = this.rightSideIconTarget.dataset.iconClose;
    this.rightSideIconTarget.innerHTML = iconContent;

    this.compareButtonTarget.classList.add('is-selected');
    close(this.panelTarget)
  }

  handleClear() {  
    if(this.compareButtonTarget.classList.contains('is-selected') == true) {
      this.compareButtonTarget.classList.remove('is-selected');
  
      const iconContent = this.rightSideIconTarget.dataset.iconChevron;
      this.rightSideIconTarget.innerHTML = iconContent;
    
      this.textTarget.textContent = "Comparer à ...";

      this.clearSelectedItem();
      this.selectDefaultCategory();
    }
  }
}