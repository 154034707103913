import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static targets = ["chart1", "chart2", "chart3", "chart4"];

  connect() {
    const openData = this.generateDayWiseTimeSeries(100, 72);
    if (this.hasChart1Target) {
      this.initializeChart(this.chart1Target, openData);
    }
    if (this.hasChart2Target) {
      this.initializeChart(this.chart2Target, openData);
    }
    if (this.hasChart3Target) {
      this.initializeChart(this.chart3Target, openData);
    }
    if (this.hasChart4Target) {
      this.initializeChart(this.chart4Target, openData);
    }
  }

  initializeChart(target, openData) {
    const options = this.chartOptions();
    const data = this.generateDayWiseTimeSeries(60, 72, true);
    const series = [{
        name: 'PM10',
        type: 'column',
        data
      },
      {
        name: 'Open Data PM10',
        type: 'line',
        data: openData
      },
    ];
    options.series = series;
    options.chart.id = target.id;

    const chart = new ApexCharts(target, options);
    chart.render();
  }

  chartOptions() {
    return {
      chart: {
        group: 'simple-comparaison',
        type: 'bar',
        height: 300,
        foreColor: "#999",
        stacked: true,
        // dropShadow: {
        //   enabled: false,
        //   enabledSeries: [0],
        //   top: -2,
        //   left: 2,
        //   blur: 5,
        //   opacity: 0.06
        // }
      },
      colors:['#397ee6'],
      stroke: {
        width: [0, 2, 5],
        curve: 'smooth'
      },
      dataLabels: {
        enabled: false
      },
      series: null,
      markers: {
        size: 0,
        strokeColor: "#fff",
        strokeWidth: 3,
        strokeOpacity: 1,
        fillOpacity: 1,
        hover: {
          size: 6
        }
      },
      xaxis: {
        type: "datetime",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        title: {
          text: 'μg/m³'
        },
        labels: {
          offsetX: 14,
          offsetY: -5
        },
        tooltip: {
          enabled: false
        },
        // opposite: false,
        // min: (min) => {
        //   Math.min(...options["series"][1]["data"]);
        //   console.log('Min:', min);
        //   return min;
        // },
        // max: (max) => {
        //   Math.max(...options["series"][1]["data"]);
        //   console.log('Max:', max);
        //   return max;
        // },
        min: 0,
        max: 100,
        // tickAmount: 20
      },
      grid: {
        padding: {
          left: 10,
          right: 5
        }
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy HH:mm"
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      },
      fill: {
        opacity: [0.85,0.25,1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      }
    };
  }

  generateDayWiseTimeSeries(s, count, applyColor = false) {
    var i = 0;
    var series = [];
    var x = new Date().getTime();
    while (i < count) {
      var val = Math.floor(Math.random() * s) + 1;
      if (applyColor) {
        var color = this.applyColorState(val);
        series.push({x, y:val, fillColor:color.fillColor});
      } else {
        series.push({x, y:val});
      }
      x -= 600000;
      i++;
    }

    return series;
  }

  applyColorState(value) {
    let color = null;
    const green = { strokeColor: '#F5FFF5', fillColor: '#00C200' };
    const yellow = { strokeColor: '#FFFDF5', fillColor: '#FFD900' };
    const red = { strokeColor: '#FFF5F5', fillColor: '#FF3E56' };

    if (value < 35) color = green;
    else if (value < 50) color = yellow;
    else color = red;

    return color;
  }
}