import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"
import { open, close, isOpened } from "../utils/display"

export default class extends Controller {
  static targets = ["panel"]

  connect() {
    useClickOutside(this)
  }

  toggle() {
    isOpened(this.panelTarget) ? close(this.panelTarget) : open(this.panelTarget)
  }

  clickOutside() {
    if (this.hasPanelTarget) {
      close(this.panelTarget)
    }
  }

  close() {
    close(this.panelTarget)
  }

  open() {
    open(this.panelTarget)
  }
}