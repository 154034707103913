import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["details"];

  showDay(event) {
    const activeTab = this.element.querySelector(
      ".m-weather-tabs__head__item--active"
    );
    const targetDetail = this.detailsTarget.querySelector(
      `#details-${event.currentTarget.id}`
    );

    activeTab.classList.remove("m-weather-tabs__head__item--active");

    event.currentTarget.classList.add("m-weather-tabs__head__item--active");

    this.detailsTarget
      .querySelectorAll(`[id^=details-]`)
      .forEach(el => el.classList.add("u-visually-hidden"));
    targetDetail.classList.remove("u-visually-hidden");
  }
}
