import { Controller } from "@hotwired/stimulus"
import { showV2, hideV2 } from "../utils/display";

export default class extends Controller {
  static targets = ['grid', 'table', 'buttonGrid', 'buttonTable']

  toggleDisplay(event) {
    const { currentTarget } = event;

    if (currentTarget === this.buttonGridTarget) {
      this.buttonGridTarget.classList.add('is-active');
      this.buttonTableTarget.classList.remove('is-active');
      showV2(this.gridTarget);
      hideV2(this.tableTarget);
    } else if (currentTarget === this.buttonTableTarget) {
      this.buttonTableTarget.classList.add('is-active');
      this.buttonGridTarget.classList.remove('is-active');
      showV2(this.tableTarget);
      hideV2(this.gridTarget);
    }
  }
}