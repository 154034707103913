import { Controller } from "@hotwired/stimulus";
import { showV2, hideV2, isHiddenV2 } from "../utils/display";

export default class extends Controller {
  static targets = ["panel", "icon", "buttonText"];

  toggle() {
    const buttonIcon = this.iconTarget.querySelector(".svg-icon");
    
    if (isHiddenV2(this.panelTarget)) {
      this.showV2();
      const iconContent = buttonIcon.dataset.iconHide;
      buttonIcon.innerHTML = iconContent;
      buttonIcon.classList.remove("small");

      this.buttonTextTarget.innerHTML = "Masquer les paramètres"
    } else {
      this.hideV2();
      const iconContent = buttonIcon.dataset.iconShow;
      buttonIcon.innerHTML = iconContent;
      buttonIcon.classList.add("small");
      
      this.buttonTextTarget.innerHTML = "Afficher les paramètres"
    }
  }

  hideV2() {
    hideV2(this.panelTarget);
  }

  showV2() {
    showV2(this.panelTarget);
  }
}
