import flatpickr from "flatpickr";
import { endOfDay } from "date-fns";
import { French } from "flatpickr/dist/l10n/fr";

export const initDateRangePicker = (target, options) => {
  return flatpickr(target, {
    mode: "range",
    dateFormat: "d/m/Y H:i",
    maxDate: endOfDay(new Date()),
    ...options
  })
}