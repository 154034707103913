import { Controller } from "@hotwired/stimulus"
import { showV2, hideV2 } from "../utils/display"

const buildInput = (type) => {
  const input = document.createElement('input')
  input.name = 'display'
  input.type = 'hidden'
  input.value = type

  return input
}

export default class extends Controller {
  static targets = ['table', 'grid', 'link', 'form', 'buttonGrid', 'buttonTable']

  toggleDisplay(event) {
    const { currentTarget } = event;
  
    if (currentTarget === this.buttonGridTarget) {
      this.updateDom('grid')
      this.buttonGridTarget.classList.add('is-active');
      this.buttonTableTarget.classList.remove('is-active');
      showV2(this.gridTarget);
      hideV2(this.tableTarget);
    } else if (currentTarget === this.buttonTableTarget) {
      this.updateDom('table')
      this.buttonTableTarget.classList.add('is-active');
      this.buttonGridTarget.classList.remove('is-active');
      showV2(this.tableTarget);
      hideV2(this.gridTarget);
    }
  }

  updateDom(type) {
    this.addDisplayQueryParam(type)
    this.updateFormDisplayParam(type)
    this.updateSortLinkDisplayParam(type)
  }
  
  addDisplayQueryParam(type) {
    const url = new URL(window.location.href)
    url.searchParams.set('display', type)
    window.history.replaceState({}, '', url)
  }
  
  updateFormDisplayParam(type) {
    this.formTargets.forEach(element => {
      const hiddenField = element.querySelector('input[type=hidden][name=display]')
      if (hiddenField) {
        hiddenField.value = type
      } else {
        element.appendChild(buildInput(type))
      }
    })
  }
  
  updateSortLinkDisplayParam(type) {
    this.linkTargets.forEach(element => {
      const url = new URL(element.href)
      url.searchParams.set('display', type)
      element.href = url.href
    })
  }
}
