import { Controller } from "@hotwired/stimulus"
import { open, close } from "../utils/display"

export default class extends Controller {
  static targets = [
    'cancelModal',
    'confirmModal',
    'sensorsModal',
    'nameCharCount',
    'ignoreButton',
    'migrateButton',
    'nameInput',
    'createMessage',
    'createLoading'
  ]

  resetNameCharCount () {
    if (this.hasNameInputTarget) {
      this.updateNameCharCount({currentTarget: this.nameInputTarget})
    }
  }

  closeModal() {
    if (this.hasCancelModalTarget) {
      close(this.cancelModalTarget)
    }

    if (this.hasConfirmModalTarget) {
      close(this.confirmModalTarget)
    }

    if (this.hasSensorsModalTarget) {
      close(this.sensorsModalTarget)
    }
  }

  showCancelModal() {
    open(this.cancelModalTarget)
  }

  showConfirmModal() {
    open(this.confirmModalTarget)
  }
  showSensorsModal() {
    open(this.sensorsModalTarget)
  }

  removeCompanyModal() {
    document.getElementById('modal-company').innerHTML = ''
  }

  updateNameCharCount ({currentTarget}) {
    const availableLength = currentTarget.maxLength - currentTarget.value.length
    this.nameCharCountTarget.textContent = this.nameCharCountTarget.textContent.replace(/\d+/, availableLength)
  }

  showMigrate() {
    this.ignoreButtonTarget.classList.add('is-hidden')
    this.migrateButtonTarget.classList.remove('is-hidden')
  }

  showIgnore() {
    this.migrateButtonTarget.classList.add('is-hidden')
    this.ignoreButtonTarget.classList.remove('is-hidden')
  }

  reloadSensorsDatatable({ detail, currentTarget }) {
    const { field, value } = detail
    if (field == 'sensors_company_id') {
      const url = new URL(currentTarget.dataset.sensorsUrl)
      url.searchParams.set('company_id', value)
  
      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'text/vnd.turbo-stream.html'
        }
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }
  displayCreateMessage() {
    this.createLoadingTarget.classList.add('is-hidden')
    this.createMessageTarget.classList.remove('is-hidden')
  }
}