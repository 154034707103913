import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = [ 'drawer', 'active' ]

  initialize() {
    let largeMenu = document.cookie.split(';').filter(e => e.includes('sidebar_is_smaller'))[0]
    largeMenu = largeMenu ? largeMenu.split('=')[1] === 'false' : true
    useClickOutside(this)
    this.openingSpeed = this.element.dataset.expandableSpeed || 200
    if ( this.hasActiveTarget && largeMenu) {
      this.element.classList.add('is-expanded')
      this.open(this.drawerTarget, this.openingSpeed)
    } else {
      this.drawerTarget.style.display = 'none'
    }
  }

  toggle(e) {
    e.preventDefault()
    if ( window.getComputedStyle(this.drawerTarget).display === 'none' ) {
      if(e.params.closeOthers) this.closeOthers()
      this.element.classList.add('is-expanded')
      this.open(this.drawerTarget, this.openingSpeed)
    } else {
      this.element.classList.remove('is-expanded')
      this.close(this.drawerTarget, this.openingSpeed)
    }
  }

  close(target, duration) {
    target.style.transitionProperty = 'height, margin, padding, opacity'
    target.style.transitionDuration = duration + 'ms'
    target.style.height = target.offsetHeight + 'px'
    target.offsetHeight
    this.resetProperties(target)
    window.setTimeout( () => {
      target.style.display = 'none'
      this.removeProperties(target, ['height', 'padding-top', 'padding-bottom', 'margin-top', 'margin-bottom', 'opacity', 'overflow', 'transition-duration', 'transition-property'])
    }, duration)
  }

  open(target, duration) {
    target.style.removeProperty('display')
    let display = window.getComputedStyle(target).display
    if (display === 'none') display = 'block'
    target.style.display = display
    const height = target.offsetHeight
    this.resetProperties(target)
    target.offsetHeight
    target.style.transitionProperty = "height, margin, padding, opacity"
    target.style.transitionDuration = duration + 'ms'
    target.style.height = height + 'px'
    this.removeProperties(target, ['padding-top', 'padding-bottom', 'margin-top', 'margin-bottom', 'opacity'])
    window.setTimeout( () => {
      this.removeProperties(target, ['height', 'overflow', 'transition-duration', 'transition-property'])
    }, duration)
  }

  resetProperties(element) {
    element.style.overflow = 'hidden'
    element.style.height = 0
    element.style.paddingTop = 0
    element.style.paddingBottom = 0
    element.style.marginTop = 0
    element.style.marginBottom = 0
    element.style.opacity = 0
  }

  removeProperties(element, properties) {
    for (const property of properties) {
      element.style.removeProperty(property)
    }
  }

  closeOthers() {
    if(this.isSmallMenu()) {
      const opened = document.getElementsByClassName('is-expanded')
      for (const element of opened) {
        element.classList.remove('is-expanded')
        const target = element.getElementsByTagName('ul')[0]
        this.close(target, this.openingSpeed)
      }
    }
  }

  clickOutside() {
    if (this.drawerTarget.classList.contains('l-sidebar__sublist') && this.isSmallMenu()) {
      this.drawerTarget.parentNode.classList.remove('is-expanded')
      this.close(this.drawerTarget, this.openingSpeed)
    }
  }

  isSmallMenu() {
    let smallMenu = document.cookie.split(';').filter(e => e.includes('sidebar_is_smaller'))[0] || false
    return smallMenu = smallMenu && smallMenu.split('=')[1]  === 'true'
  }
}
